import React, { useEffect, useState } from 'react';
import './../../../Styles/Perfil.css';
import hombre from '../images/hombre.png';
import mujer from '../images/mujer.png';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress'; 
import GraficaArea from './GraficaHistorial';
import DatosUsuarioPerfil from '../../component/footer/datosUsuarioPerfil';
import { getHistorico } from '../../domain/api-client/UserMachine';
import theme from '../../../app/config/themaConfig';

const Perfil = ({ data, edad, nombre, genero, email, DataRangos, usuario, handleComponentRango, handleComponentChange, textStyle }) => {

  const [dataLoaded, setDataLoaded] = useState(false);
  const [Data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(async () => {
    try {
      
      setTimeout(async () => {
        const result = await getHistorico(usuario);
        setData(result.content);
        setDataLoaded(true);
        setLoading(false); 
      }, 2000); 
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); 
    }
  }, [data, edad, nombre, email, DataRangos]);

  const avatarSrc = genero === 'F' ? mujer : hombre;
  const backgroundTheme = process.env.REACT_APP_BACKGROUND_IMAGE;

  // Define los colores de los cuadros según el valor de REACT_APP_BACKGROUND_IMAGE
  let lightColor = "#66B3FF";
  let mediumColor = "#3399FF";
  let darkColor = "#3399FF";

  if (backgroundTheme === "background_dev") {
    lightColor = "#f58484";
    mediumColor = "#f55858";
    darkColor = "#e73f3f";
  } else if (backgroundTheme === "background_prod") {
    lightColor = "#66B3FF";
    mediumColor = "#3399FF";
    darkColor = "#0077FF";

  } else if (backgroundTheme === "background_qa") {
    lightColor = "#5feddf";
    mediumColor = "#04cfbb";
    darkColor = "#04897c";
  }
  const primaryColor = theme.palette.primary.main;

  return (
    <div className="perfil-container">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress style={{ marginLeft: '0%', marginTop: '10px',color:primaryColor }} />
        </div>
      ) : (
        <>
          <DatosUsuarioPerfil textStyle={textStyle} handleComponentRango={handleComponentRango} handleComponentChange={handleComponentChange} />
          <div className="header">
            <Avatar alt="Remy Sharp" src={avatarSrc} sx={{ width: 56, height: 56 }} />
            <div className="profile-info">
              <h2 className='nombreperfil' style={textStyle}>{capitalizeFirstLetter(nombre) || "-"}</h2>
              <p className='email' style={textStyle}>{email || "-"}</p>
            </div>
          </div>
          <div className='scroll-container'>
            <h2 className='h2tusdatos' style={textStyle}>Tus datos</h2>
            <div className="data-section">
              <div className="data-box light-blue" style={{ backgroundColor: lightColor }}>
                <p className='numerosTusdatos'>{edad || "-"}</p>
                <span>Edad(años)</span>
              </div>
              <div className="data-box medium-blue" style={{ backgroundColor: mediumColor }}>
                <p className='numerosTusdatos'>{data.peso || "-"}</p>
                <span>Peso(kg)</span>
              </div>
              <div className="data-box dark-blue" style={{ backgroundColor: darkColor }}>
                <p className='numerosTusdatos'>{data.altura || "-"}</p>
                <span>Altura(m)</span>
              </div>
            </div>
            <h2 style={{ color: primaryColor }}>Historial</h2>
            <div className="chart-card">
              <div className="chart-content">
                {dataLoaded && <GraficaArea data={Data} DataRangos={DataRangos} />}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Perfil;
