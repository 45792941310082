import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});

export default function Deposits() {
    const classes = useStyles();
    return (
        <>
            <Title>Diastolico</Title>
            <Typography component="p" variant="h4">
                111 mmHg
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                10 de Mayo, 2021
            </Typography>

            <Title>Sistolica</Title>
            <Typography component="p" variant="h4">
                75 mmHg
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                10 de Mayo, 2021
            </Typography>
            
        </>
    );
}