import React from 'react';
import { Grid } from '@material-ui/core';
import '../../Styles/stylesIMC.css';
import LineChartSistolica from './LinechartSistolica';
import LineChartDiastolica from './LinechartDiastolica';



const HistoricoPresion = ({ DataRangos, data, textStyle }) => {


    return (
        <div className="card">
            <div className="grafica-radial-historic">
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ height: "20%" }}>
                        <div className='titlegraphicPresion' style={textStyle}>Sistolica</div>

                        <LineChartSistolica width="100%" data={data} DataRangos={DataRangos} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ height: "20%" }}>
                        <div className='titlegraphicPresion' style={textStyle}>Diastolica</div>

                        <LineChartDiastolica width="100%" data={data} DataRangos={DataRangos} />
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}

export default HistoricoPresion;