import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});

export default function Deposits() {
    const classes = useStyles();
    return (
        <>
            <Title>Peso</Title>
            <Typography component="p" variant="h4">
                76.6 Kg
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                26 de Abril, 2021
            </Typography>

            <Title>Estatura</Title>
            <Typography component="p" variant="h4">
                170 cm
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                26 de Abril, 2021
            </Typography>
            
        </>
    );
}