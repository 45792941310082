import React, { useEffect, useState } from 'react';
import './footerStyles.css';



const calcularRangoOximetria = (DataRangos, oximetria) => {
    const oximetriaNum = parseFloat(oximetria);

    let selectedRangoOximetria = null;

    for (const rango of DataRangos) {
        const parametros = JSON.parse(rango.parametros);

        if (parametros && parametros.oxigeno) {
            const rangoOximetria = parametros.oxigeno.split('-').map(Number);


            if (
                oximetriaNum <= rangoOximetria[0] &&
                oximetriaNum >= rangoOximetria[1]
            ) {
                if (!selectedRangoOximetria || rangoOximetria[1] > selectedRangoOximetria.oxigeno) {
                    selectedRangoOximetria = { ...rango, oxigeno: rangoOximetria[1] };
                }
            }
        }
    }

    if (selectedRangoOximetria) {
        const label = selectedRangoOximetria.valor;
        const descripcion = selectedRangoOximetria.descripcion;

        return {
            label,
            descripcion,
        };
    } else {
        return {
            label: 'No se encuentra en ningún rango',
            descripcion: '',
        };
    }
};

export default function TusDatosOXI({ oxi, DataRangos, textStyle }) {
    const oximetria = oxi.oximetria;
    const [Rango, setRango] = useState("");
    const oximetriaNum = oximetria !== undefined ? parseFloat(oximetria) : 0;
    const [oximetriaFormatted, setOximetriaFormatted] = useState("");


    useEffect(() => {
        if (!isNaN(oximetria)) {
            // Verificar si el número es decimal
            const isDecimal = !Number.isInteger(oximetriaNum);

            if (DataRangos && DataRangos.length > 0) {
                const { label } = calcularRangoOximetria(DataRangos, oximetriaNum);
                setRango(label);
                // Formatear el número según si es decimal o no
                const formattedOximetria = isDecimal ? oximetriaNum.toFixed(2) : oximetriaNum.toString();
                // Actualizar el estado con el número formateado
                setOximetriaFormatted(formattedOximetria);
            } else {
                setRango('No hay rangos disponibles');
            }
        } else {
            setRango('Valor de oximetría no válido');
        }
    }, [oximetria, DataRangos]);

    return (
        <div className='buttonHistoricPresion'>
            <button className="buttonUnic">
                <div className="number" style={textStyle}><b>{oximetria ? oximetriaFormatted + "%" : "-"}</b></div>
                <div className="text" style={textStyle}>{Rango}</div>
            </button>
        </div>
    );
}
