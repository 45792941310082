import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Grid,
    Button,
    Box
} from '@material-ui/core';
import ClockIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import { useStyles } from './SinginStyles';
import theme from '../../../app/config/themaConfig';


export default function Recuperar({ onBack, setShowForgot, setShowRecuperar }) {

    const classes = useStyles();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const [countdown, setCountdown] = useState(120);

    useEffect(() => {
        setShowForgot(false);
        startCountdown();
    }, []);

    // Función para iniciar el contador
    const startCountdown = () => {
        const countdownInterval = setInterval(() => {
            if (countdown > 0) {
                setCountdown((prevCountdown) => prevCountdown - 1);
            } else {
                clearInterval(countdownInterval);
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    };


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        if (minutes < 0 || remainingSeconds < 0) {
            return '0:00';
        }

        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const reenviar = () => {
        setShowRecuperar(false);
        setShowForgot(true);
    };

    const ingresar = () => {
        setShowRecuperar(false);
        setShowForgot(false);
    };

    const dashedLineStyles = {
        borderTop: '1px dashed #27ABE2',
        margin: '10px 0px',
        width: '379%',
        marginTop: '35%',
        marginLeft: '13%',
    };
    const primaryColor = theme.palette.primary.main;
    const countdownStyles = {
        color: primaryColor,
        fontWeight: 'bold',
        marginLeft: '4px',
    };

    

    return (
        <div className={classes.centerv}>
            <div className='containerlogin'>
                <Paper className={borderRadius}>
                    <div className={classes.margin}>
                        <Grid container justify="center" style={{ marginTop: '15px' }}>
                            <img src='https://centraldevida.s3.amazonaws.com/Logos/EVBI+-+Logo__3.png' width="120" alt="Logo" />
                        </Grid>
                        <div className='textoRecuperar'>
                            Se te ha enviado un mensaje a tu celular con tu nuevo código
                        </div>
                        <div className='textoRecuperar'>
                            ¿Ya recibiste tu código?
                        </div>
                        <form>
                            <Grid container justifyContent="space-between" style={{ marginTop: '10px' }}>
                                <Box sx={{ width: '80%' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            textTransform: 'none', width: '180%', marginLeft: "114%", borderRadius: '10px',
                                            marginTop: '0',
                                            height: '30px'
                                        }}
                                        onClick={() => ingresar()}
                                    >
                                        Ingresar
                                    </Button>
                                    <hr style={dashedLineStyles} />

                                </Box>
                                <div className='textoRecuperar2' style={{color:primaryColor}}>
                                    Si no te llegó el código, podemos enviarlo nuevamente
                                </div>
                                <Typography variant="body2" style={{ textAlign: 'center', display: 'flex', alignItems: 'center', paddingLeft: '24%', marginTop: '5%' }}>
                                    <ClockIcon style={{ color: primaryColor, marginRight: '4px' }} />
                                    <span style={countdownStyles}>{formatTime(countdown)}</span>
                                </Typography>
                            </Grid>
                            <div className='textoRecuperar'>
                            </div>

                            <div className={classes.wrapper}>
                                <Grid container justifyContent="space-between" style={{ marginTop: '35px' }}>
                                    <Box sx={{ width: '80%' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                textTransform: 'none', width: '121%', marginLeft: "68%", borderRadius: '10px',
                                                marginTop: '-19%',
                                                height: '30px'
                                            }}
                                            disabled={countdown > 0}
                                            onClick={() => reenviar()}
                                        >
                                            Volver a enviar
                                        </Button>
                                    </Box>
                                    <b className='versionforgot' style={{color:primaryColor}}>{`${process.env.REACT_APP_VERSION}`}</b>
                                </Grid>
                                <Grid container justify="center" style={{ marginTop: '20px' }}>
                                </Grid>
                            </div>
                        </form>
                    </div>
                </Paper>
            </div>
        </div>
    );
}
