import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaQuestion, FaRegCalendarDays, FaStar } from "react-icons/fa6";
import '../../Styles/stylesIMC.css'

const calcularTamañoHollow = (DataRangos, rangovalue) => {


  // Buscar el rango que coincide con el rangovalue
  const rangoEncontrado = DataRangos.find((rango) => rango.valor === rangovalue);

  if (rangoEncontrado) {
    const { valor, descripcion } = rangoEncontrado;

    // Calcular el hollowSize según el rango encontrado
    return {

      label: valor ? valor : 'no se encontró rango',
      descripcion: descripcion ? descripcion : '',

    };
  } else {
    // Manejar el caso en el que no se encuentra el rango
    return {
      hollowSize: '0',
      label: 'No se encontró rango',
      descripcion: '',

    };
  }
};

const CardPresion = ({ sistolica, diastolica, DataRangos, handleComponentChange, handleComponentRango, textStyle, rangovalue }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [Rango, setRango] = useState('');
  const [Descripcion, setDescripcion] = useState('');
  const handleButtonClick = (accion, key) => {
    handleComponentChange(accion);
    handleComponentRango(key)

  };

  useEffect(() => {
    if (DataRangos && DataRangos.length > 0) {
      const { label, descripcion } = calcularTamañoHollow(DataRangos, rangovalue);
      setRango(label);
      setDescripcion(descripcion);
    } else {
      setRango('No hay rangos disponibles');
      setDescripcion('');
    }
    if (sistolica != null && diastolica != null) {
      const timeoutId = setTimeout(() => {
        setShowDescription(true);
      }, 500);

      // Limpiar el temporizador cuando el componente se desmonta o cuando cambian sistolica o diastolica
      return () => clearTimeout(timeoutId);
    }
  }, [DataRangos, sistolica, diastolica]);

  const options = {
    series: [
      {
        name: 'Ideal',
        data: [
          {
            x: 'Sistolica',
            y: [50, 120],
            goals: [
              {
                name: 'Actual',
                value: sistolica ?? 0,
                strokeWidth: 2,
                strokeDashArray: 2,
                strokeColor: '#79385f',
              },
            ],
          },
          {
            x: 'Diastolica',
            y: [40, 80],
            goals: [
              {
                name: 'Actual',
                value: diastolica ?? 0,
                strokeWidth: 2,
                strokeDashArray: 2,
                strokeColor: '#79385f',
              },
            ],
          },
        ],
      },
    ],
    chart: {
      height: 250,
      type: 'rangeBar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ['#1983c4'],
    dataLabels: {
      enabled: true,
      offsetY: -3,
      offsetX: sistolica - 20,
      style: {
        fontSize: '12px',
        colors: ['#79385f'],
      },
      formatter: function (val, opt) {
        if (opt.dataPointIndex === 0) {
          return sistolica ?? 0;
        } else {
          return diastolica ?? 0;
        }
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Óptima ', 'Actual'],
      markers: {
        fillColors: ['#1983c4', '#79385f']
      }
    },
    toolbar: {
      show: false,
    },
  };

  return (
    <div className="card">
      <div className="card-content">
        <div className="grafica-radial-container">

          <div className="grafica-radial">
            <ReactApexChart options={options} series={options.series} type='rangeBar' height={170} />
            {showDescription && (
              <div>
                <div className='titledescription' style={textStyle}>{Rango}</div>
                <div className='descripcionpresion' style={{ textAlign: 'justify', color: textStyle.color }}>{Descripcion}</div>
              </div>
            )}
          </div>

          <div className="botonespresion">
            <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
              <FaQuestion style={textStyle} />
              <p style={textStyle}>Información</p>
            </button>
            <button className="botonescard" onClick={() => handleButtonClick('Container8', 'presion')} style={textStyle}>
              <FaRegCalendarDays style={textStyle} />
              <p style={textStyle}>Historial</p>

            </button>
            <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
              <FaStar style={textStyle} />
              <p style={textStyle}>Tips</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPresion;