import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Paper, Typography } from '@material-ui/core';
import Title from '../Title';
import { Grid, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Link } from 'react-router-dom';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import MigaPan from '../../component/Utils/MigaPan';
import imgPa from '../images/rangos-presion-arterial.png';
/* import Tabla_sistolica from '../../../img/tabla_sistolico_distolico.svg' */
import "../../../Styles/stylesPages.css"
import PlusPresionAterial from '../subComponents/plusPresion/componet/plusPresionArterial';
import { ImcPortalStyle } from '../../Imc/ImcPortalStyle';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../user/domain/userSlice';
import clsx from 'clsx';
import { getUserMachineNew } from '../../domain/api-client/UserMachine';
const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    media: {
        height: 290,
        width: "100%",
        paddingTop: '70.25%', // 16:9
        paddingRight: '60.25%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function InfoPA({ formatted_date, data, cardTitle }) {

    const classes = ImcPortalStyle();
    const userAuth = useSelector(selectUser);
    const [pa, setPa] = useState([]);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
   
    useEffect(async() => {
        const result = await getUserMachineNew(userAuth.userPrincipal.username);
        const user = result.respuesta.presiones; 
        try{
            let paSort = user.sort().slice(-5)
            setPa(paSort)
        }catch{
            console.log("Fallo el servicio getUserMachine")
        }
    }, [])


    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Información Presión Alterial" />
            </Grid>
            <Grid  item xs={12} md={8} lg={12}>
                <Paper className={fixedHeightPaper}>
                    <PlusPresionAterial dataPA={pa}/>
                </Paper>
            </Grid>
            
            {/*<Grid item xs={12} sm={12}>
                {/* <Card className={classes.root}>
                    <CardContent> 
                <Typography align="center" className={classes.title} color="textSecondary" gutterBottom>
                    <Title >Rangos de la PA</Title>
                    <CardMedia
                        className='card-media'
                        image={Tabla_sistolica}
                        title="PA"
                    />

                </Typography>
                {</CardContent>
                </Card> 
            </Grid>
            <Grid item xs={12} sm={12}>
                <Card className={classes.root}>
                    <CardContent> 

                <Typography align="center" className={classes.title} color="textSecondary" gutterBottom>
                    <Title >PRESIÓN ARTERIAL</Title>
                    <p className={classes.title}>
                        La presión arterial es la fuerza que la sangre ejerce contra las paredes arteriales. Cuando el médico mide la presión arterial,
                        el resultado se registra con dos números. El primer número, llamado presión arterial sistólica, es la presión causada cuando el corazón se
                        contrae y empuja la sangre hacia afuera. El segundo número, llamado presión arterial diastólica, es la presión que ocurre cuando el corazón
                        se relaja y se llena de sangre. El resultado de la medición de la presión arterial usualmente se expresa colocando el número de la presión arterial
                        sistólica sobre el número de la presión arterial diastólica, por ejemplo, 138/72. La presión arterial normal para adultos
                        se define como una presión sistólica de menos de 120 y una presión diastólica de menos de 80. Esto se indica como 120/80.
                    </p>
                    <Title >¿Tengo la presión arterial alta?</Title>
                    <p className={classes.title}>
                        a presión arterial alta generalmente se define como 130 o más para el primer número,
                        u 80 o más para el segundo número (anteriormente los números eran 140/90)
                    </p>
                </Typography>
                {/* </CardContent>
                </Card> 
            </Grid>*/}


        </>
    );
}