import { makeStyles } from '@material-ui/core/styles';


export const DashboardStyle = makeStyles((theme) => ({
    root: {
    
    overflow:'hiden'

   
    },
  
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh'
   
    },
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
   
     
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 290,
    },
  }));