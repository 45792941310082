import React, { useEffect } from "react";

const TablaOxiSVG = ({ percentage, setColorRango }) => {
  const colors = [
    { color: "#a92a41", label: "Hipoxia Moderada", min: 0, max: 91 },
    { color: "#48457c", label: "Hipoxia Leve", min: 91, max: 95 },
    { color: "#1983c4", label: "Normal", min: 95, max: 100 },

  ];

  const borderRadius = 8;
  const sectionWidth = 106;
  const sectionHeight = 30;
  const totalWidth = 318;


  // Encuentra el rango correspondiente según el porcentaje
  let rangeIndex = -1;
  for (let i = 0; i < colors.length; i++) {
    if (percentage >= colors[i].min && percentage <= colors[i].max) {
      rangeIndex = i;
      break;
    }
  }


  useEffect(() => {
    setColorRango(colors[rangeIndex].color)

  }, []);

  return (
    <svg width={totalWidth} height={sectionHeight} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clipPath">
          <rect width={totalWidth} height={sectionHeight} rx={borderRadius} ry={borderRadius} />
        </clipPath>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: "white", stopOpacity: 0 }} />
          <stop offset="10%" style={{ stopColor: "white", stopOpacity: 1 }} />
          <stop offset="90%" style={{ stopColor: "white", stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: "white", stopOpacity: 0 }} />
        </linearGradient>
      </defs>

      <rect
        x="0"
        y="0"
        width={totalWidth}
        height={sectionHeight}
        fill={`url(#gradient)`}
        rx={borderRadius}
        ry={borderRadius}
      />

      {colors.map((item, index) => (
        <g key={index}>
          <rect
            x={index * sectionWidth}
            y="0"
            width={sectionWidth}
            height={sectionHeight}
            fill={item.color}
            clipPath="url(#clipPath)"
          />
          <text
            x={index * sectionWidth + sectionWidth / 2}
            y="15"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
            fontSize="10"
            fontWeight="bold"

          >
            {`${item.min}-${item.max}`}
          </text>
        </g>
      ))}
    </svg>
  );
};

export default TablaOxiSVG;
