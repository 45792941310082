import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ErrorModal = ({ open, onClose, errorMessage }) => {
  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <div style={{ borderRadius: '8px', padding: '16px', textAlign: 'center', background: '#f0f0f0' }}>
          <p>{errorMessage}</p>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorModal;
