import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
} from '@material-ui/core';
import clsx from 'clsx';

import MigaPan from '../../../component/Utils/MigaPan';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../user/domain/userSlice';
import { getUserMachine, getUserMachineNew } from '../../../domain/api-client/UserMachine';
import Chart from '../plusImc/component/Chart';
import { ImcPortalStyle } from '../../../Imc/ImcPortalStyle';
import { UtilsDate } from '../../../../orussystem/utils/UtilDate';
import ChartImc from './component/plusInfoIgc';
import ChartIgc from './component/plusInfoIgc';







export const PanelPlusIgc = (props) => {

    const classes = ImcPortalStyle();
    const userAuth = useSelector(selectUser);
    const [lIcms, setLIcms] = useState([]);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const[ generoUser, setGeneroUser]= useState("");

    useEffect(async() => {
        const result = await getUserMachineNew(userAuth.userPrincipal.username);
        const user = result.respuesta.imcs; 
        setGeneroUser(generoUser)
        try{
            let imcSort = user.sort().slice(-5)
            setLIcms(imcSort)

        }catch{
            console.log("Fallo el servicio getUserMachine")
        }
    }, [])
    

    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Historial mediciones IMC" />
            </Grid>
            <Grid  item xs={12} md={8} lg={12}>
                <Paper className={fixedHeightPaper}>
                    <Chart lIcms={lIcms}/>
                </Paper>
            </Grid>
           


        </>
    )

}