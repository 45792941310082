import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { CardContent, Typography } from '@material-ui/core';
import Title from '../Title';
import Card from '@material-ui/core/Card';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CardMedia from '@material-ui/core/CardMedia';
import "../../../Styles/stylesPages.css"
import CardActionArea from '@material-ui/core/CardActionArea';
import MigaPan from '../../component/Utils/MigaPan';
import imgPGC1 from '../images/bodyfat_table_women_ES.jpg';
import imgPGC2 from '../images/bodyfat_table_men_ES.jpg';
import ChartImc from '../subComponents/plusIgc/component/plusInfoIgc';
import { getHistoricMachineByPhones, getUserMachine, getUserMachineNew } from '../../domain/api-client/UserMachine';
import { ImcPortalStyle } from '../../Imc/ImcPortalStyle';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../user/domain/userSlice';
import ChartIgc from '../subComponents/plusIgc/component/plusInfoIgc';
import ChartIGC from '../subComponents/plusIgc/component/plusInfoIgc';
import ChartOximetria from '../subComponents/plusOximetria/component/plusInfoOximetria';

export default function InfoOXIMETRIA({ edad, genero }) {

    const [oximetria, setOximetria] = useState([]);
    const classes = ImcPortalStyle();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const userAuth = useSelector(selectUser);

    useEffect(async() => {
        const result = await getUserMachineNew(userAuth.userPrincipal.username);
        const user = result.respuesta.oximetrias; 
        try{
            let oximetria = user.sort().slice(-5)
            setOximetria(oximetria)
        }catch{
            console.log("Fallo el servicio getUserMachine")
        }
    }, [])


    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Información Oximetria" />
            </Grid>

            <Grid  item xs={12} md={8} lg={12}>
                <Paper className={fixedHeightPaper}>
                    <ChartOximetria oximetria={oximetria}/>
                </Paper>
            </Grid>

        </>
    );
}