import React, { useMemo, forwardRef } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Box, Button, ListItem
} from '@material-ui/core';

import PostAddIcon from '@material-ui/icons/PostAdd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WarningIcon from '@material-ui/icons/Warning';


import Title from '../../domain/Title';

// Generate Order Data
function createData(id, name, key, status, dateConextion) {
    return { id, name, key, status, dateConextion };
}

const rows = [
    createData(1, 'Pepsico 1', 'PEPSICO_1', 'Activa', '2021-05-14 11:30'),
    createData(2, 'Pepsico 2', 'PEPSICO_2', 'Activa', '2021-05-14 11:30'),
];

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        display: "flex",
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

export default function List() {

    let to = "/mediciones/user"

    const renderLink = useMemo(
        () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    const classes = useStyles();
    return (
        <>
            <Box
                component="span"
                m={1}
                className={`${classes.spreadBox} ${classes.box}`}
            >
                <Title>Basculas </Title>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                >
                    Exportar
                </Button>
            </Box>

            <Table size="small">
                <TableHead>
                    <TableRow align="center">
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Key </TableCell>
                        <TableCell align="center">Ultima Conexion</TableCell>
                        <TableCell align="center">Estado</TableCell>
                        <TableCell align="center">Advertencia</TableCell>
                        <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows.map((row) => (
                        <TableRow key={row.id} >
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.key}</TableCell>
                            <TableCell align="center">{row.dateConextion}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                           
                            <TableCell align="center"><WarningIcon color="primary" /></TableCell>

                            <TableCell align="center" >
                                <ListItem button component={renderLink}>
                                    <PostAddIcon />
                                </ListItem>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault}>
                    See more orders
        </Link>
            </div>
        </>
    );
}