import React from 'react';
import { menus } from '../domain/menuJson';
import { DefaultDashboard, HelpMenuDashboard } from '../../dashboard';


export const UserAdmin = (props) => {
    const components = {
        Default: DefaultDashboard,
        Home: DefaultDashboard
    };

    return (
        <>
            {HelpMenuDashboard().RenderMenu(menus(), components)}
        </>
    );
}

