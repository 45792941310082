import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        padding:'0 0 0 0'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
      },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 290,
    },

    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(1)
    },
  
    borderRadius: {
        borderRadius: '3%'
    },
}));