import React from 'react';

/**
 * 
 */
export const UtilsDate = () => {

    /**
     * 
     * @param {Date} date 
     */
    const formatDDMMMYYYY = ( date) => {


        const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        let current_datetime = date;
        let formatted_date = current_datetime.getDate() + " de " + months[current_datetime.getMonth()] + ", " + current_datetime.getFullYear()

        return (
            formatted_date
        )

    }


    const formatDDMM = (date) => {

        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        let current_datetime = date;
        let formatted_date = current_datetime.getDate() + "/" + months[current_datetime.getMonth()] 

        return (
            formatted_date
        )
    }

    return {
        formatDDMMMYYYY,
        formatDDMM
    };

}