import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChartOXI = ({ data, DataRangos }) => {
    // Función para calcular el rango óptimo
    const calcularRangoOptimo = () => {
        // Define el rango óptimo de oxígeno
        return [95, 100];
    };

    // Función para calcular el rango y descripción según la oximetría
    const calcularRangoYDescripcion = (oximetria) => {
        for (const rango of DataRangos) {
            const parametros = JSON.parse(rango.parametros);

            if (parametros && parametros.oxigeno) {
                const [max, min] = parametros.oxigeno.split('-').map(Number);

                if (oximetria > min && oximetria <= max) {
                    return {
                        rango: rango.valor,
                        descripcion: rango.descripcion,
                    };
                }
            }
        }

        return {
            rango: '',
            descripcion: '',
        };
    };
    const lastTenData = data.slice(-5).reverse();

    // Extraer valores de oxímetria y fechas correspondientes
    const oximetriaValues = lastTenData.map((record) => record.oximetria);
    const dates = lastTenData.map((record) => {
        const date = new Date(record.datecreated);
        const day = date.getDate();
        const monthNames = [
            "ene", "feb", "mar", "abr", "may", "jun",
            "jul", "ago", "sep", "oct", "nov", "dic"
        ];
        const month = monthNames[date.getMonth()];
        return `${day} ${month}`;
    });


    const [rangoOptimoMin, rangoOptimoMax] = calcularRangoOptimo();

    // Configuración de la gráfica
    const options = {
        chart: {
            id: "line-chart",
            width: "80%",
        },
        xaxis: {
            categories: dates,
        },
        yaxis: {
            min: 80,
            max: 100,
            tickAmount: 2,
            labels: {
                colors: ["#005aa9"],
                formatter: function (value) {
                    return Math.round(value);
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 0.2,
                opacityFrom: 0.7,
                opacityTo: 0.7,
                stops: [0, 100],
            },
        },
        stroke: {
            width: 3,
            colors: ["#005aa9"],
            opacity: 3,
        },
        markers: {
            size: 4,
            colors: '#005aa9', // Color de los puntos (en este caso, el color de peso)
        },
        annotations: {
            yaxis: [
                {
                    y: rangoOptimoMin,
                    y2: rangoOptimoMax,
                    fillColor: "#005aa9",
                    opacity: 0.2,
                },
            ],
        },
        tooltip: {
            custom: function ({ series, dataPointIndex }) {
                const date = dates[dataPointIndex];
                const oximetriaValue = series[0][dataPointIndex];
                const { rango, descripcion } = calcularRangoYDescripcion(oximetriaValue);

                return (
                    '<div class="custom-tooltip" style="height:50px; width:155px;font-size:12px;border-radius:10%;">' +
                    '<div class="tooltip-title" style="background-color: #005aa9; color: white; padding-left: 10px; opacity:1">' +
                    date +
                    '</div>' +
                    '<div class="tooltip-content" style="height: 60px;padding-top: 5px; ">' +
                    '<div class="sales-circle" style="background-color: #005aa9; border-radius: 50%; width: 10px; height: 10px; display: inline-block; margin-right: 5px;margin-left: 10px; "></div>' +
                    '<span class="sales-text" style="font-weight: bold; margin: 0;">' + oximetriaValue + ": " + rango +
                    '</span>' +
                    '</div>' +
                    '</div>'
                );
            },
        },
    };

    return (
        <div>
            <div>
                <ReactApexChart options={options} series={[{ name: "Oxi", data: oximetriaValues }]} type="line" height={200} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "-5%", marginLeft: "-30%", marginBottom: "6%" }}>
                <div style={{ marginRight: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                width: "10px",
                                height: "3px",
                                backgroundColor: "#005aa9",
                                marginRight: "5px",
                            }}
                        ></div>
                        Oximetria
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#005aa9",
                                opacity: 0.2,
                                marginRight: "5px",
                            }}
                        ></div>
                        Rango Óptimo
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LineChartOXI;
