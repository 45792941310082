import React from 'react';
import { DefaultDashboard, HelpMenuDashboard } from '../dashboard';
import ListMachine from './component/ListMachine';
import { Home } from './home/Home';
import { Imc } from './Imc/imcPortal';
import { MedicionesUser } from './MedicionesUser/MedicionesUser';
import InfoImc from './MedicionesUser/pages/InfoImc';
import InfoPA from './MedicionesUser/pages/InfoPA';
import InfoPGC from './MedicionesUser/pages/InfoPGC';
import { menus } from './menu/menuJson';
import { PollPanel } from './Polls/PollPanel';
import { PresionAlterial } from './PresionAlterial/PresionAlterial';
import { UserTable } from './user';
import { PanelUser } from './UserProfile/panelUser';
import { PanelPlusIgc } from './MedicionesUser/subComponents/plusIgc/panelPlusIgc';
import {PanelQuestion} from '../../src/centraldevida/Polls/component/panelQuestion';
import { PanelQuestionPublic } from '../../src/centraldevida/Polls/component/panelQuestionPublic';
import InfoOXIMETRIA from './MedicionesUser/pages/InfoOXIMETRIA';
import DatosUsuario from './component/footer/datosUsuario';

export const CentralDeVida = () => {

  const components = {
    Default: DefaultDashboard,
    Home: Home,
    DatosUsuario:DatosUsuario,
    Imc: Imc,
    PresionAlterial: PresionAlterial,
    UserTable: UserTable,
    ListMachine: ListMachine,
    MedicionesUser: MedicionesUser,
    InfoImc : PanelPlusIgc,
    InfoPA: InfoPA,
    InfoPGC: InfoPGC,
    InfoOXIMETRIA: InfoOXIMETRIA,
    Encuestas: PollPanel,
    PerfilUsuarios: PanelUser,
    Question: PanelQuestion,
    QuestionPublic: PanelQuestionPublic

  };

  return (
    <>
      {HelpMenuDashboard().RenderMenu(menus(), components)}
    </>
  );
}
