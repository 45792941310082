import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { CardContent, Typography } from '@material-ui/core';
import Title from '../Title';
import Card from '@material-ui/core/Card';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CardMedia from '@material-ui/core/CardMedia';
import "./../../../Styles/stylesPages.css"
import CardActionArea from '@material-ui/core/CardActionArea';
import MigaPan from '../../component/Utils/MigaPan';
import imgPGC1 from '../images/bodyfat_table_women_ES.jpg';
import imgPGC2 from '../images/bodyfat_table_men_ES.jpg';

/* //hombres
import table_20_39 from '../../../img/hombres-pgc-20-39.svg'
import table_40_59 from  '../../../img/hombres-pgc-40-59.svg'
import table_60_79 from  '../../../img/hombres-pgc-60-79.svg'

//Mujeres
import table_f_20_39 from '../../../img/mujeres-pgc-20-39.svg'
import table_f_40_59 from  '../../../img/mujeres-pgc-40-59.svg'
import table_f_60_79 from  '../../../img/mujeres-pgc-60-79.svg' */
import ChartImc from '../subComponents/plusIgc/component/plusInfoIgc';
import { getHistoricMachineByPhones, getUserMachine, getUserMachineNew } from '../../domain/api-client/UserMachine';
import { ImcPortalStyle } from '../../Imc/ImcPortalStyle';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../user/domain/userSlice';
import ChartIgc from '../subComponents/plusIgc/component/plusInfoIgc';
import ChartIGC from '../subComponents/plusIgc/component/plusInfoIgc';
export default function InfoPGC({ edad, genero }) {

    const [pa, setPa] = useState([]);
    const classes = ImcPortalStyle();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const userAuth = useSelector(selectUser);
    const [generoUser, setGeneroUser]= useState("")
  
    const handledTableHombre = () => {
        /* if(edad < 39){
            return table_20_39;
        }else if( edad <= 59 && edad >= 40){
            return table_40_59;
        }else if( edad <= 79  && edad > 59 ){
            return table_60_79;
        } */
    }

    const handledTableMujeres = () => {
        /* if(edad < 39){
            return table_f_20_39;
        }else if( edad <= 59 && edad >= 40){
            return table_f_40_59;
        }else if( edad <= 79  && edad > 59 ){
            return table_f_60_79;
        } */
    }


    useEffect(async() => {
        const result = await getUserMachineNew(userAuth.userPrincipal.username);
        const genero =await getUserMachine(userAuth.userPrincipal.username);
        const generoUser = genero.respuesta.user.genero
        setGeneroUser(generoUser)
        const user = result.respuesta.igcs; 
        try{
            let paSort = user.sort().slice(-5)
            setPa(paSort)
        }catch{
            console.log("Fallo el servicio getUserMachine")
        }
    }, [])


    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Información Porcentaje Grasa" />
            </Grid>

            <Grid  item xs={12} md={8} lg={12}>
                <Paper className={fixedHeightPaper}>
                    <ChartIGC dataPGC={pa} generoUser={generoUser}/>
                </Paper>
            </Grid>
            
           
           {/* {genero !== "M" ?
                (<Grid item xs={12} sm={6}>
                    <Card className={classes.root}> 
                    <CardContent> 

                    <Typography align="center" className={classes.title} color="textSecondary" gutterBottom>
                        <Title >PGC en Mujeres</Title>

                        <CardMedia
                            className="card-media-sisto"
                            image={handledTableMujeres()}
                            title="IMC"
                        />

                    </Typography>
                     </CardContent> 
                    </Card> 
                </Grid>
                ) : (
                    <Grid item xs={12} sm={6}>
                        {/* <Card className={classes.root}> 
                         <CardContent> 

                        <Typography align="center" className={classes.title} color="textSecondary" gutterBottom>
                            <Title >PGC en Hombres </Title>

                            <CardMedia
                                className="card-media-sisto"
                                image={handledTableHombre()}
                                title="IMC"
                            />

                        </Typography>
                         </CardContent> 
                         </Card> 
                    </Grid>
                )

            }
            <Grid item xs={12} >
                 <Card className={classes.root}> 
                 <CardContent> 
                <Typography align="center" className={classes.title} color="textSecondary" gutterBottom>
                    <Title >PORCENTAJE DE GRASA CORPORAL</Title>
                    <p className={classes.title}>
                        La grasa es parte de nuestro cuerpo como también lo es el agua,
                        los huesos o el músculo. Por lo tanto, la grasa corporal es necesaria,
                        nos beneficia conformando un tejido de sostén y un lugar privilegiado
                        para la producción y regulación de muchas hormonas.

                        El porcentaje saludable general de grasa corporal
                        para los hombres debería variar del 6% al 24% y para las mujeres está entre el 14% y el 31%
                    </p>
                </Typography>
                 </CardContent>
                 </Card>
            </Grid>*/}


        </>
    );
}