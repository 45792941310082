import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import MigaPan from '../component/Utils/MigaPan';
import { Style } from './PollStyle';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { selectUser } from '../../user/domain/userSlice'


import Quetions from './component/Quetions';

import Title from '../../orussystem/Conponent/Title';
import { getListAllPoll } from '../domain/api-client/UserMachine';

import { Alert } from '@material-ui/lab';
import {
    Paper,
    Typography,
    Grid,
    TextField,
    Button,
    Container, CircularProgress
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ListPolls } from './component/ListPolls';
import { PausePresentation } from '@material-ui/icons';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
}));


export const PollPanel = (props) => {

    //const classes = Style();
    const classes = useStyles();
    const [dataTable, setDataTable] = useState([])

    useEffect(async () => {
        const response = await getListAllPoll(0,200);
        setDataTable(response.content)
    }, []);



    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Encuestas" />
            </Grid>

            <Grid item xs={12}>
                {/* <Paper className={classes.paper}> */}
                    <ListPolls
                    row={dataTable}
                    />
                {/* </Paper> */}
            </Grid>


        </>
    )

}