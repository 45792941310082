import React from "react";
import { useEffect, useState } from "react";


const RangeSVG = ({ percentage }) => {
  const [formattedPercentage, setFormattedPercentage] = useState("0.0");


  useEffect(() => {
    const newFormattedPercentage = (percentage || percentage === 0) ? percentage.toFixed(1) : "0.0";
    setFormattedPercentage(newFormattedPercentage);
  }, [percentage]);

  const customRanges = [
    { color: "#1983c4", label: "Rango 1", min: 0, max: 18.5 },
    { color: "#005aa9", label: "Rango 2", min: 18.5, max: 25 },
    { color: "#48457c", label: "Rango 3", min: 25, max: 30 },
    { color: "#79385f", label: "Rango 4", min: 30, max: 35 },
    { color: "#a92a41", label: "Rango 5", min: 35, max: 40 },
    { color: "#d91c23", label: "Rango 6", min: 40, max: 1000 },
  ];

  const borderRadius = 8;
  const sectionWidth = 53;
  const sectionHeight = 0;
  const marginTop = 20;
  const totalWidth = customRanges.length * sectionWidth;
  const totalHeight = sectionHeight + marginTop;

  // Encuentra el rango correspondiente según el porcentaje
  let rangeIndex = -1;
  for (let i = 0; i < customRanges.length; i++) {
    if (percentage >= customRanges[i].min && percentage <= customRanges[i].max) {
      rangeIndex = i;
      break;
    }
  }


  let textX = 0;
  if (rangeIndex !== -1) {
    textX = rangeIndex * sectionWidth - 10 + sectionWidth / 2; // Centro del rango
  }


  return (
    <div>
      <svg width={totalWidth} height={totalHeight} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="clipPath">
            <rect width={totalWidth} height={totalHeight} rx={borderRadius} ry={borderRadius} />
          </clipPath>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "white", stopOpacity: 0 }} />
            <stop offset="10%" style={{ stopColor: "white", stopOpacity: 1 }} />
            <stop offset="90%" style={{ stopColor: "white", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "white", stopOpacity: 0 }} />
          </linearGradient>
        </defs>

        <rect
          x="0"
          y={marginTop}
          width={totalWidth}
          height={sectionHeight}
          fill={`url(#gradient)`}
          rx={borderRadius}
          ry={borderRadius}
        />

        {customRanges.map((item, index) => (
          <g key={index}>
            <rect
              x={index * sectionWidth}
              y={marginTop}
              width={sectionWidth}
              height={sectionHeight}
              fill={item.color}
              clipPath="url(#clipPath)"
            />
          </g>
        ))}
      </svg>
      {rangeIndex !== -1 && (
        <div style={{ position: "relative", textAlign: "center" }}>
          <div style={{ position: "absolute", left: textX, bottom: 3 }}>
            <span style={{ color: customRanges[rangeIndex].color, fontWeight: "bold" }}>{`${formattedPercentage}`}</span>
          </div>
          <div style={{ position: "absolute", left: textX + 6, top: -15 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 20 20">
              <polygon fill={customRanges[rangeIndex].color} points="10,20 20,0 0,0" />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeSVG;
