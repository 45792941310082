import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { IoHomeSharp } from "react-icons/io5";
import { RiHeartPulseFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import './footerStyles.css'
import theme from '../../../app/config/themaConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Segoe UI variablevariable',
    padding: '0px 0px',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    borderRadius: '60px'
  },
  buttoninactive: {
    color: "#E9E9EC"
  },
  buttonactive: {
    color: "primary"
  }
}));

export default function Footer({ handleComponentChange, handleComponentRango, textStyle }) {
  const [activeButton, setActiveButton] = useState(null);
  const handleButtonClick = (accion, buttonColor, key) => {
    setActiveButton((prevColor) => {
      if (prevColor === buttonColor) {
        return null; // Restablece el color si se hace clic en el mismo botón
      } else {
        return buttonColor; // Cambia al color del botón actual si es diferente
      }
    });
    handleComponentRango(key);
    handleComponentChange(accion);
  };
  
  const primaryColor = theme.palette.primary.main;

  return (
    <div className="botonescontainer">
      <button
        onClick={() => handleButtonClick('Container1', 'inicio', 'imc')}
        className={activeButton === 'inicio' ? 'active-button botones' : 'botones'}
        style={{
          backgroundColor: activeButton === 'inicio' ? primaryColor : '#f2f2f2',
          color: activeButton === 'inicio' ? 'white' : primaryColor,
        }}
      >
        <IoHomeSharp />
        <p>Inicio</p>
      </button>
      <button
        onClick={() => handleButtonClick('Container2', 'mediciones', 'imc')}
        className={activeButton === 'mediciones' ? 'active-button botones' : 'botones'}
        style={{
          backgroundColor: activeButton === 'mediciones' ? primaryColor : '#f2f2f2',
          color: activeButton === 'mediciones' ? 'white' : primaryColor,
        }}
      >
        <RiHeartPulseFill />
        <p>Mediciones</p>
      </button>
      <button
        onClick={() => handleButtonClick('Container3', 'perfil', 'imc')}
        className={activeButton === 'perfil' ? 'active-button botones' : 'botones'}
        style={{
          backgroundColor: activeButton === 'perfil' ? primaryColor : '#f2f2f2',
          color: activeButton === 'perfil' ? 'white' : primaryColor,
        }}
      >
        <FaUserAlt />
        <p>Perfil</p>
      </button>
    </div>
  );
}
