export const menus = () => {

    return (
        [
            {
                id: 1,
                rol: "ROLE_USER",
                icon: "timeline",
                label: "Mediciones",
                type: "menu",
                to: "/",
                component: "MedicionesUser",
                submenus: [
                    {
                        id: 2,
                        icon: "assignmentTurnedIn",
                        label: "Informacion IMC",
                        type: "submenu",
                        show: true,
                        to: "/info-imc",
                        component: "InfoImc"
                    },
                    {
                        id: 3,
                        icon: "personAdd",
                        label: "Informacion PA",
                        type: "submenu",
                        to: "/info-pa",
                        component: "InfoPA",
                    },
                    {
                        id: 4,
                        icon: "accessibility",
                        label: "Informacion PGC",
                        type: "submenu",
                        to: "/info-pgc",
                        component: "InfoPGC"
                    },
                    {
                        id: 5,
                        icon: "accessibility",
                        label: "Informacion Oximetria",
                        type: "submenu",
                        to: "/info-oximetria",
                        component: "InfoOXIMETRIA"
                    },
                ],

            }

            
            , {
                id: 5,
                rol: "ROLE_USER",
                icon: "helpIcon",
                label: "Encuestas",
                type: "menu",
                to: "/Encuestas",
                isOpen:false,
                component: "Encuestas",
                submenus: [
                    {
                        id: 6,
                        icon: "accessibility",
                        label: "Question",
                        type: "submenu",
                        show: false,
                        to: "/Encuestas/Question/:id",
                        component: "Question"
                    },
                    {
                        id: 7,
                        icon: "accessibility",
                        label: "QuestionPublic",
                        type: "submenu",
                        show: false,
                        to: "/Encuestas/QuestionPublic/:id",
                        component: "QuestionPublic"
                    },
                ],
            }
            , {
                id: 6,
                rol: "ROLE_USER",
                icon: "accessibility",
                label: "Perfil",
                type: "menu",
                to: "/user-profile",
                component: "PerfilUsuarios",
                submenus: [
                ],
            }
            
        ]
    )


}
