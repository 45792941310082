import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IoLogOutOutline } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { logout } from '../../../user/domain/userSlice';
import '../../../Styles/medicionesUser.css';
import Stack from '@mui/material/Stack';
import hombre from '../../MedicionesUser/images/hombre.png';
import mujer from '../../MedicionesUser/images/mujer.png';

export default function HeaderAvatar({
  genero,
  handleComponentChange,
  handleComponentRango,
  Key,
}) {
  const [avatar, setAvatar] = useState('');
  const [caso, setCaso] = useState('');
  const [llave, setLlave] = useState('');
  const [showLogout, setShowLogout] = useState(false); // Agregar esta línea
  const dispatch = useDispatch();

  useEffect(() => {
    switch (Key) {
      case 'imc':
        setCaso('Container1');
        setLlave('imc');
        break;
      case 'presion':
        setCaso('Container4');
        setLlave('presion');
        break;
      case 'pgc':
        setCaso('Container5');
        setLlave('pgc');
        break;
      case 'oximetria':
        setCaso('Container6');
        setLlave('oximetria');
        break;
      default:
        break;
    }
  }, [Key]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleButtonClick = (accion, key) => {
    handleComponentChange(accion);
    handleComponentRango(key);
  };

  const handleClick = () => {
    setShowLogout(true); // Hacer la acción de logout al hacer clic en el avatar
  };

  useEffect(() => {
    const avatarSrc = genero === 'F' ? mujer : hombre;
    setAvatar(avatarSrc);
  }, [genero]);

    return (
        <>
            <Stack direction="row" width="50%" spacing={2}>
                <h1 style={{paddingTop:'10%'}}>
                    <IoIosArrowBack fontSize='60px' className='iconback' onClick={() => handleButtonClick(caso, llave)} />
                </h1>
        <div className='espacioavatar'></div>
        <div className='espacioavatar'></div>
        <div className='espacioavatar'></div>
        <div className='espacioavatar'></div>
               
        <div className="containavatar">
        <img
  src={avatar}
  style={{ width: '120px', height: '125px',marginTop:'-17%' }}
  alt="Avatar2"
  onClick={() => setShowLogout(!showLogout)}
/>

          {showLogout && (
            <div className="logout-bubble">
              <button className="logout-option" onClick={handleLogout}>
                <IoLogOutOutline className='iconlogout'></IoLogOutOutline> Cerrar sesión
              </button>
            </div>
          )}
        </div>
            </Stack>
        </>
    );
}