import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, AreaChart } from 'recharts';
import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    LabelList,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import Title from '../../../Title';
import { UtilsDate } from '../../../../../orussystem/utils/UtilDate';
import { Bar } from 'recharts';
import { BarChart } from 'recharts';

import { CartesianGrid } from 'recharts';
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, Paper } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ImcPortalStyle } from '../../../../Imc/ImcPortalStyle';
import clsx from 'clsx';
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(15),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
// Generate Sales Data
function createData(time, amount) {
    return { time, amount };
}


export default function Chart({ lIcms }) {
    const classes = ImcPortalStyle();
    const fixedHeightPaper = clsx(classes.fixedHeight1, classes.container);
    const theme = useTheme();
    const history = useHistory()
    const l = lIcms !== undefined ? lIcms.length == 1 ? [...lIcms, ...lIcms] : lIcms : []


    const data = l.map(imc => {
        let imcal = Math.round(imc.imc * 100) / 100
        return ({
            id: imc.id,
            name: UtilsDate().formatDDMM(new Date(imc.datecreate)),
            obesidad: imcal > 29.9 ? imcal : 0,
            obesidad2: imcal < 35 && imcal > 40 ? imcal : 0,
            obesidad3: imcal > 40 ? imcal : 0,
            sobrepeso: imcal < 29.9 && imcal > 25 ? imcal : 0,
            normal: imcal < 24.9 && imcal > 18.5 ? imcal : 0,
            bajo: imcal < 18.5 ? imcal : 0,
        })
    })

    const goBack = () => {
        history.push("/")
    }


    return (
        <>
            <Title>Indice de Masa Corporal</Title>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            layout="vertical"
                            width={500}
                            height={300}
                            data={data}
                            margin={{ top: 20, right: 10, left: 0, bottom: 40 }}
                        >
                            <XAxis type="number" />
                            <YAxis dataKey="name" type="category" scale="band" />
                            <Tooltip />
                            <Bar dataKey="obesidad" stackId="a" fill="#ec1f26" />
                            <Bar dataKey="sobrepeso" stackId="a" fill="#fcd704" />
                            <Bar dataKey="normal" stackId="a" fill="#2bb673" />
                            <Bar dataKey="bajo" stackId="a" fill="#41c8f5" />
                        </ComposedChart>
                    </ResponsiveContainer>
         

            <Grid container spacing={2} style={{ padding: 0, marginTop: '10px', marginLeft: '1px', }}>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: '#41c8f5' }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Bajo</Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Bajo: "}</b>{' '}
                                            {"Personas con un IMC menor a 18,5."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: '#2bb673' }} />
                            </Grid>
                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Normal</Grid>
                            <Grid item  style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Normal: "}</b>{' '}
                                            {"Personas con un IMC entre 18,5 y 25."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>

                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: '#fcd704' }} />
                            </Grid>
                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Sobre peso</Grid>
                            <Grid item  style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Sobrepeso: "}</b>{' '}
                                            {"Personas con un IMC entre 25 y 30."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: '#ec1f26' }} />
                            </Grid>
                            <Grid item xs={12} iv style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Obesidad Clase I</Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Obesidad Clase I: "}</b>{' '}
                                            {"Personas con un IMC entre 30 y 35."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>

                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: '#7030A0' }} />
                            </Grid>
                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Obesidad Clase II</Grid>
                            <Grid item  style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Obesidad Clase II: "}</b>{' '}
                                            {"Personas con un IMC entre 35 y 40."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>

                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: '#261036' }} />
                            </Grid>
                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Obesidad Clase III</Grid>
                            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Obesidad Clase III: "}</b>{' '}
                                            {"Personas con un IMC mayor a 40."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none", width: "inherit", marginTop: 20 }}
                onClick={goBack}
            >
                Volver
            </Button>
        </>
    );
}

