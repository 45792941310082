import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import hombre from '../../MedicionesUser/images/hombre.png'
import mujer from '../../MedicionesUser/images/mujer.png'
import '../../../Styles/medicionesUser.css'
import { logout } from '../../../user/domain/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { IoLogOutOutline } from "react-icons/io5";

export default function DatosUsuario({ nombre, genero }) {
  let formattedNombre = "-";
  if (nombre) {
    const nombreDividido = nombre.split(' ')[0];
    formattedNombre = nombreDividido.charAt(0).toUpperCase() + nombreDividido.slice(1);
  }
  const Nombre = nombre;
  const [showLogout, setShowLogout] = useState(false);
  const [avatar, setAvatar] = useState("");

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    const avatarSrc = genero === 'F' ? mujer : hombre;
    setAvatar(avatarSrc)
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

  }, [genero]);

  const handleClickOutside = (event) => {
    if (!event.target.closest('.containavatar')) {
      setShowLogout(false);
    }
  };

  return (
    <>
      <Stack direction="row" width="50%" spacing={2}>
        <h1>
          <p>¡Hola </p>
          <p className='nombre'>{formattedNombre}!</p>
        </h1>
        <div className='espacioavatar'></div>
        <div className='espacioavatar'></div>

        <div className="containavatar">
          {avatar && (<img src={avatar} alt="Avatar" onClick={() => setShowLogout(!showLogout)} />)}
          
          {showLogout && (
            <div className="logout-bubble">
              <button className="logout-option" onClick={handleLogout}>
                <IoLogOutOutline className='iconlogout'></IoLogOutOutline> Cerrar sesión
              </button>
            </div>
          )}
        </div>
      </Stack>
    </>
  );
}