export const menus = () => {

    return (
        [
            {
                id: 1,
                rol: "ROLE_ADMIN",
                icon: "people",
                label: "Plataforma",
                type: "menu",
                to: "/admin",
                component: "Home",
                submenus: [
                    {
                        id: 2,
                        icon: "personAdd",
                        label: "Usuarios",
                        type: "submenu",
                        to: "/admin/users",
                    },
                    {
                        id: 3,
                        icon: "apps",
                        label: "Codigos Respuesta",
                        type: "submenu",
                        to: "/admin/response-code",
                    },
                    {
                        id: 4,
                        icon: "apps",
                        label: "Codigos Respuesta",
                        type: "submenu",
                        to: "/admin/a;ertas",
                    },
                ],
            },
          
        ]
    )


}