import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: process.env.REACT_APP_PRIMARY_THEMA
            
        },
        secondary: {
            main: process.env.REACT_APP_SECONDARY_THEMA,
        },
    }
});



export default theme;