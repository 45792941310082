import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { IoIosConstruct } from "react-icons/io";
import '../../Styles/cardsStyles.css'
import theme from '../../app/config/themaConfig';




const Construccion = ({ }) => {
    const primaryColor = theme.palette.primary.main;
    const textStyle = {
        color: primaryColor,
        fontFamily: 'Segoe UI Variable Static Small 700',
        fontWeight: 'bold',

    }

    return (

        <div className="card2" style={{ height: '320px' }}>
            <div className="card-content2">
                <div id="chart2" >
                    <div className="grafica-radial2" style={{ height: '280px' }}>
                        <IoIosConstruct style={{ fontSize: '100px', marginTop: '60px', color: primaryColor }}></IoIosConstruct>
                        <p style={textStyle}> pagina en construcción...</p>
                        <p style={{ ...textStyle, textAlign: 'justify',padding:'20px' }}>
                            Próximamente encontrarás mucha más información sobre tus indicadores de salud. No dejes de utilizar EVBi
                        </p>

                    </div>

                </div>
            </div>
        </div>
    );
}


export default Construccion;