import React from "react"
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Grid, Typography, ListItem, Tooltip } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import EditIcon from '@material-ui/icons/Edit';
import Title from "../../../orussystem/Conponent/Title";
import CardQuestion from "./cardQuestion"
import CardQuestionPublic from "./cardQuestionPublic";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        display: "flex",
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    }
}));


export const ListPolls = ({ isLoadPoll, row }) => {

    let r = row !== undefined && row !== null ? row : []

    const classes = useStyles();
    const history = useHistory();

    const handleRowClick = (row) => {
        history.push(`/Encuestas/Question/${row.id}`);
    }

    const handleRowClickPublic = (row) => {
        history.push(`/Encuestas/QuestionPublic/${row.id}`);
    }

    return (
        <>
            {/* <Box
                component="span"
                m={1}
                className={`${classes.spreadBox} ${classes.box}`}
            >
                <Title>Lista de encuestas</Title>
            </Box>

            <Table size="small">
                <TableHead>
                    <TableRow alignItems="center">
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Descripcion</TableCell>
                        <TableCell align="center">Estado</TableCell>
                        <TableCell align="center" colSpan="2">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoadPoll ? <>cargando...</> : r.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.describe}</TableCell>
                            <TableCell align="center">{row.state}</TableCell>
                            <TableCell align="center" onClick={() => handleRowClick(row)}>
                                <ListItem button >
                                    <Tooltip title="Realizar Encuesta" aria-label="top-end">
                                        <PostAddIcon className={classes.tableAction} v />
                                    </Tooltip>
                                </ListItem>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={(e) => e.preventDefault}>
                    See more orders
                </Link>
            </div>

            <br />
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
            >
            </Grid>
            <br /> */}
            {isLoadPoll ? <>cargando...</> : r.map((row) => (
                <div>
                    <CardQuestion date={row} onClick={() => handleRowClick(row)}/>
                    <br />
                </div>
            ))}
            {/*} {isLoadPoll ? <>cargando...</> : r.map((row) => (
                <div>
                    <CardQuestionPublic date={row} onClick={() => handleRowClickPublic(row)}/>
                    <br />
                </div>
            ))}*/}

        </>
    );

}