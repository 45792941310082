import React from 'react';

import { Route } from "react-router-dom";

export const HelpMenuDashboard = () => {

    const componetRetur = (component = 'Default', components) => {

        var n = components[component];
        var comp = n ? React.createElement(n) : (<h1>{`Componente no definido ${component}`}</h1>);

        return (
            comp
        )

    }

    const renderSubMenus = (m, components) => {

        return (
            <Route key={m.id} exact 
                path={m.to}
                component={() => componetRetur(m.component, components)}
            />
        )
    }

    const RenderMenu = (_menus, components) => {

        return (
            _menus.map((m) => {
                var menu = renderSubMenus(m, components)
                var m1 = m.submenus.map((sm) => {
                    let submenu = renderSubMenus(sm, components)
                    return submenu;
                })
                return m1.concat(menu);
            })
        )

    }

    return {
        RenderMenu
    };
};



