import React, { useState, useEffect } from 'react';

import './../../Styles/medicionesUser.css'
import { Button, Container, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { ImcPortalStyle } from './ImcPortalStyle';
import { getUserDocumentMachine, getRangos } from '../domain/api-client/UserMachine';
import { useSelector } from 'react-redux';
import { selectUser } from '../../user/domain/userSlice';
import { UtilsDate } from '../../orussystem/utils/UtilDate';
import { USER_ID } from '../../app/api-client/constants';
import Footer from '../component/footer/footer'
import DatosUsuario from '../component/footer/datosUsuario';
import { styled } from '@mui/material';
import CardImc from '../Imc/CardImc'
import TusDatos from '../component/footer/Tusdatos';
import Mediciones from './pages/CardsMediciones';
import Perfil from './pages/Perfil';
import CardPresion from '../PresionAlterial/CardPresion';
import CardIgc from '../Igc/CardIgc';
import CardOximetria from '../Oximetria/CardOximetria';
import ErrorModal from './component/ModalError';
import HeaderAvatar from '../component/footer/HeaderAvatar';
import TusDatosIMC from '../component/footer/TusdatosIMC';
import HistoricoIMC from '../Imc/HistoricoIMC';
import TusDatosPresion from '../component/footer/TusdatosPresion';
import HistoricoPresion from '../PresionAlterial/HistoricoPresion';
import TusDatosIGC from '../component/footer/TusdatosIGC';
import HistoricoIGC from '../Igc/HistoricoIGC';
import TusDatosOXI from '../component/footer/TusdatosOxi';
import HistoricoOXI from '../Oximetria/HistoricoOxi';
import EditUser from './pages/EditUser';
import theme from '../../app/config/themaConfig';
import CardBasal from '../BasalMetabolis/CardBasal';
import CardSkeletal from '../SkeletalMuscle/cardSkeletal';
import CardVisceral from '../VisceralFatLevel/cardVisceralFatLevel';
import Construccion from '../Oximetria/pageConstruction';

const FullscreenContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: 400%;
  padding: 0px 0px 0px 0px;
  background-position: center center;
  border: 1px solid transparent;
`;
const BackgroundHeader = styled('div')`
  /* Background image for header */
  flex: 1;
  width: 100%;
  height: 150px;
  margin-top: 7%;
  color: white;
  margin-left: 12%;
  display: block;
  
`;


export const MedicionesUser = (props) => {

    const [showContainer1, setShowContainer1] = useState(true);
    const classes = ImcPortalStyle();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const id_user_login = localStorage.getItem(USER_ID)

    const [userMachine, setUserMachine] = useState({});
    const [paramGenerict, setParamGenerict] = useState({})
    const [userAnswer, setUserAnswer] = useState({});
    const [isResponsePoll, setIsResponsePoll] = useState(false);

    //Modal change genero
    const [isOpneModal, setisOpneModal] = useState(false)

    const userAuth = useSelector(selectUser);
    const [lIcms, setLIcms] = useState([]);
    const [genero, setGenero] = useState("")
    const [IMC, setIMC] = useState("")
    const [IMCS, setIMCS] = useState("")
    const [rangoIMC, setRangoIMC] = useState("")


    const [Sistolica, setSistolica] = useState("")
    const [diastolica, setDistolica] = useState("")
    const [Presiones, setPresiones] = useState("")



    const [IGC, setIGC] = useState("")
    const [IGCS, setIGCS] = useState("")


    const [Oximetrias, setOximetrias] = useState("")
    const [dataOxi, setdataOxi] = useState("")

    const [Basal, setBasal] = useState("")
    const [Skeletal, setSkeletal] = useState("")
    const [Visceral, setVisceral] = useState("")
    const [reload, setReload] = useState(false);






    const [key, setKey] = useState("imc")
    const [DataRangos, setDataRangos] = useState("")
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(async () => {
        try {
            const result = await getUserDocumentMachine(userAuth.userPrincipal.username);
            if (result.codigoRespuesta === "No existe usuario con ese documento") {
                setIsError(true);
                setErrorMessage("Lo sentimos, tus datos están desactualizados. Por favor, dirígete a tu Evbi más cercana.");
            } else {
                const rangos = await getRangos(key)
                const user = result.respuesta.user;
                //los rangos
                setIMCS(result.respuesta.imcs)
                setPresiones(result.respuesta.presiones)
                setIGCS(result.respuesta.igcs)
                setdataOxi(result.respuesta.oximetrias)


                setDataRangos(rangos.respuesta)

                setUserMachine(result.respuesta.user[0]);
                const genero = result.respuesta.user[0].genero
                setGenero(genero)
                const lastIMC = result.respuesta.imcs && result.respuesta.imcs.length > 0
                    ? result.respuesta.imcs[0].imc
                    : 0;

                setIMC(lastIMC);
                const rangovalueIMC = result.respuesta.imcs && result.respuesta.imcs.length > 0
                ? result.respuesta.imcs[0].rangoValue
                : 0;
                setRangoIMC(rangovalueIMC)


                const sistolica = result.respuesta.presiones && result.respuesta.presiones.length > 0
                    ? result.respuesta.presiones[0].sistolica
                    : 0;

                const diastolica = result.respuesta.presiones && result.respuesta.presiones.length > 0
                    ? result.respuesta.presiones[0].diastolica
                    : 0;

                const igc = result.respuesta.igcs && result.respuesta.igcs.length > 0
                    ? result.respuesta.igcs[0].igc
                    : 0;


                const oximetrias = result.respuesta.oximetrias && result.respuesta.oximetrias.length > 0
                    ? result.respuesta.oximetrias[0]
                    : 0;
                const basal = result.respuesta.imcs && result.respuesta.imcs.length > 0
                    ? result.respuesta.imcs[0].basal_metabolis
                    : 0;

                const skeletal = result.respuesta.imcs && result.respuesta.imcs.length > 0
                    ? result.respuesta.imcs[0].skeletal_muscle
                    : 0;
                const visceral = result.respuesta.imcs && result.respuesta.imcs.length > 0
                    ? result.respuesta.imcs[0].visceral_fat_level
                    : 0;
      

                setSistolica(sistolica);
                setDistolica(diastolica);
                setIGC(igc);
                setOximetrias(oximetrias);
                setBasal(basal)
                setSkeletal(skeletal)
                setVisceral(visceral)

                setParamGenerict(result.respuesta);
                setLIcms(user.imcs);
                localStorage.setItem(USER_ID, user.id)


                if (user.genero === null || user.genero === "") {
                    setisOpneModal(true)
                }

            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setIsError(true);
                setErrorMessage("Nuestro sistema presenta una falla en este momento. Por favor, inténtalo más tarde.");
            } else {
                // Manejar otros errores de red o solicitud aquí
            }
        }
        setReload(false)

    }, [key, userAuth.userPrincipal.username,reload]);

    //Se recupera la ultima fecha para mostar
    let formatted_date = UtilsDate().formatDDMMMYYYY(new Date(userMachine.dateupdate))


    const imc = [
        {
            id: 1,
            label: 'Peso (Kg)',
            value: userMachine.peso
        },
        {
            id: 2,
            label: 'Altura (m)',
            value: userMachine.altura
        }
    ]

    const imcCardTitle = {
        label: "Indice de Masa Corporal",
        value: Math.round(userMachine.imc * 100) / 100
    }

    const igcCardTitle = {
        label: "Porcentaje de Grasa Corporal",
        value: Math.round(userMachine.igc * 100) / 100
    }

    const encCardTitle = {
        label: "Encuestas",
        value: userAnswer.messaje
    }

    const calcularEdad = (fecha) => {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }

        return edad;
    }
    const [activeComponent, setActiveComponent] = useState(null);

    const handleComponentChange = (componentName) => {
        setActiveComponent(componentName);
    };
    const handleComponentRango = (componentName) => {
        setKey(componentName);

    };

    const primaryColor = theme.palette.primary.main;
    const textStyle = {
        color: primaryColor,
    };

    const renderComponent = () => {
        switch (activeComponent) {
            case 'Container1':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario nombre={userMachine.firstname} className='header' genero={userMachine.genero} ></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle}  >Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} imc={IMC} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle}  >Índice de masa corporal</h2>
                            <CardImc textStyle={textStyle} valorSerie={IMC} DataRangos={DataRangos} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}
                            rangovalue={rangoIMC}></CardImc>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

            case 'Container2':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario nombre={userMachine.firstname} genero={userMachine.genero} ></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container2' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container} >
                            <div className='contenedortusdatos'>
                                <h2 className='tusdatos' style={textStyle} >Tus datos</h2>
                                <TusDatos textStyle={textStyle} data={imc} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            </div>
                            <Mediciones textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}></Mediciones>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

            case 'Container3':
                return <FullscreenContainer >
                    <Perfil textStyle={textStyle} genero={userMachine.genero} edad={calcularEdad(userMachine.edad)} nombre={userMachine.firstname} data={userMachine} email={userMachine.email} DataRangos={DataRangos} usuario={userAuth.userPrincipal.username} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}></Perfil>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

            case 'Container4':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario genero={userMachine.genero} nombre={userMachine.firstname} className='header'></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle} >Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} data={imc} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle} >Presión Arterial</h2>
                            <CardPresion textStyle={textStyle} sistolica={Sistolica} diastolica={diastolica} DataRangos={DataRangos} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}
                             rangovalue={Presiones[0].rangoValue}></CardPresion>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;
            case 'Container5':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario genero={userMachine.genero} nombre={userMachine.firstname} className='header'></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle}>Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} data={imc} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle}  >Porcentaje de grasa corporal</h2>
                            <CardIgc textStyle={textStyle} Igc={IGC} genero={userMachine.genero} DataRangos={DataRangos} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}></CardIgc>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

            case 'Container6':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario genero={userMachine.genero} nombre={userMachine.firstname} className='header'></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle} >Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} data={imc} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle} >Oximetría</h2>
                            <CardOximetria textStyle={textStyle} oximetrias={Oximetrias} DataRangos={DataRangos} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}></CardOximetria>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;
            case 'Container7':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        {key === 'imc' && <HeaderAvatar nombre={userMachine.firstname} className='header' genero={userMachine.genero} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} Key={key} ></HeaderAvatar>}
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle} >Tus Datos Actuales</h2>
                            <TusDatosIMC textStyle={textStyle} key={key} imc={IMC} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatosIMC>
                            <h2 className='indice' style={textStyle}  >Historial Índice de masa corporal</h2>
                            <HistoricoIMC textStyle={textStyle} DataRangos={DataRangos} data={IMCS} genero={userMachine.genero} altura={userMachine.altura}></HistoricoIMC>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;
            case 'Container8':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        {key === 'presion' && <HeaderAvatar nombre={userMachine.firstname} className='header' genero={userMachine.genero} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} Key={key} ></HeaderAvatar>}
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle} >Tu Presion Arterial Actual</h2>
                            <TusDatosPresion textStyle={textStyle} sistolica={Sistolica} diastolica={diastolica}></TusDatosPresion>
                            <h2 className='indice' style={textStyle}  >Historial Presion Arterial</h2>
                            <HistoricoPresion textStyle={textStyle} DataRangos={DataRangos} data={Presiones}></HistoricoPresion>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

            case 'Container9':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        {key === 'pgc' && <HeaderAvatar nombre={userMachine.firstname} className='header' genero={userMachine.genero} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} Key={key} ></HeaderAvatar>}
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={{ color: primaryColor }} >Tu Grasa Corporal Actual</h2>
                            <TusDatosIGC sistolica={Sistolica} diastolica={diastolica} Igc={IGC} DataRangos={DataRangos} genero={userMachine.genero} primaryColor={primaryColor}></TusDatosIGC>
                            <h2 className='indice' style={{ color: primaryColor }} >Historial Grasa Corporal</h2>
                            <HistoricoIGC DataRangos={DataRangos} data={IGCS} genero={userMachine.genero} ></HistoricoIGC>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;
            case 'Container10':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        {key === 'oximetria' && <HeaderAvatar nombre={userMachine.firstname} className='header' genero={userMachine.genero} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} Key={key} ></HeaderAvatar>}
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle}>Tu Oximetría Actual</h2>
                            <TusDatosOXI textStyle={textStyle} oxi={Oximetrias} DataRangos={DataRangos} ></TusDatosOXI>
                            <h2 className='indice' style={textStyle} >Historial Oximetría</h2>
                            <HistoricoOXI DataRangos={DataRangos} data={dataOxi} ></HistoricoOXI>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

            case 'Container11':
                return <FullscreenContainer >
                    <EditUser reload={reload} setReload={setReload} textStyle={textStyle} genero={userMachine.genero} edad={calcularEdad(userMachine.edad)} nombre={userMachine.firstname} data={userMachine} email={userMachine.email} DataRangos={DataRangos} usuario={userAuth.userPrincipal.username} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}></EditUser>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

            case 'Container12':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario genero={userMachine.genero} nombre={userMachine.firstname} className='header'></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle}>Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} data={imc} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle}  >Metabolismo Basal</h2>
                            <CardBasal textStyle={textStyle} Igc={Basal} genero={userMachine.genero} DataRangos={DataRangos} handleComponentChange={handleComponentChange}
                                handleComponentRango={handleComponentRango} edad={calcularEdad(userMachine.edad)}
                                peso={userMachine.peso} altura={userMachine.altura}></CardBasal>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;
            case 'Container13':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario genero={userMachine.genero} nombre={userMachine.firstname} className='header'></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle}>Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} data={imc} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle}  >Masa muscular esqueletica</h2>
                            <CardSkeletal textStyle={textStyle} Igc={Skeletal} genero={userMachine.genero} DataRangos={DataRangos} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} imc={IMC} altura={userMachine.altura} peso={userMachine.peso} ></CardSkeletal>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;
            case 'Container14':
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario genero={userMachine.genero} nombre={userMachine.firstname} className='header'></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle}>Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} data={imc} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle}  >Nivel de grasa visceral</h2>
                            <CardVisceral textStyle={textStyle} Igc={Visceral} genero={userMachine.genero} DataRangos={DataRangos} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}peso={userMachine.peso}></CardVisceral>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;
                 case 'Container15':
                    return <FullscreenContainer >
                        <BackgroundHeader >
                            <DatosUsuario genero={userMachine.genero} nombre={userMachine.firstname} className='header'></DatosUsuario>
                        </BackgroundHeader>
          
                        <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                    
                        < Container alignitems="center" className={classes.container}>
                           <Construccion/>
                        </Container>
                    </div>
                        <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                        <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                    </FullscreenContainer>;
            default:
                return <FullscreenContainer >
                    <BackgroundHeader >
                        <DatosUsuario nombre={userMachine.firstname} className='header' genero={userMachine.genero} ></DatosUsuario>
                    </BackgroundHeader>
                    <div alignitems="center" className='fixed-container' onComponentChange={handleComponentChange}  >
                        < Container alignitems="center" className={classes.container}>
                            <h2 className='tusdatos' style={textStyle}  >Tus datos</h2>
                            <TusDatos textStyle={textStyle} key={key} imc={IMC} edad={calcularEdad(userMachine.edad)} peso={userMachine.peso} altura={userMachine.altura}></TusDatos>
                            <h2 className='indice' style={textStyle}  >Índice de masa corporal</h2>
                            <CardImc  rangovalue={rangoIMC}textStyle={textStyle} valorSerie={IMC} DataRangos={DataRangos} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango}></CardImc>
                        </Container>
                    </div>
                    <Footer textStyle={textStyle} handleComponentChange={handleComponentChange} handleComponentRango={handleComponentRango} />
                    <b className='version' >{`${process.env.REACT_APP_VERSION}`}</b>
                </FullscreenContainer>;

        }
    };

    return (
        <>
            {isError ? (
                <ErrorModal open={isError} onClose={() => setIsError(false)} errorMessage={errorMessage} />
            ) : (
                renderComponent()
            )}



        </>
    );
}
