import React from 'react';

import { Link } from 'react-router-dom';
import { makeStyles, Breadcrumbs, Typography } from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        color: theme.color
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    depositContext: {
        flex: 1,
    },
}));

export default function MigaPan({namePage}) {

    const classes = useStyles();

    return (
        <Breadcrumbs aria-label="breadcrumb" color="inherit">
            <Link color="inherit" to="/" className={classes.link}>
                <HomeIcon className={classes.icon} />
                Mediciones
            </Link>
            
            <Typography color="textPrimary" className={classes.link}>
                <GrainIcon className={classes.icon} />
                {namePage}
            </Typography>
        </Breadcrumbs>


    );

}
