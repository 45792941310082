import React,  {useState, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from "react-router-dom";


import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';

import MenuMobile from './MenuMobile';
import MenuDesktop from './MenuDesktop';
import useStyles from './UseStyle';
import ListMenuItem from '../Menu/ListMenuItem';
import { useDispatch } from 'react-redux';
import { logout } from '../../../user/domain/userSlice';
import { Button, Grid } from '@material-ui/core';




function Header({ title, _menus }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [numNotifications, setNumNotifications] = useState(10);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const history =  useHistory();
    const dispatch   = useDispatch();
    const [isReadyForInstall, setIsReadyForInstall] = useState(false);

    async function downloadApp() {
        console.log("👍", "butInstall-clicked");
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
          // The deferred prompt isn't available.
          console.log("oops, no prompt event guardado en window");
          return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        console.log("👍", "userChoice", result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        setIsReadyForInstall(false);
      }

useEffect(() => {
  window.addEventListener("beforeinstallprompt", (event) => {
    // Prevent the mini-infobar from appearing on mobile.
    event.preventDefault();
    console.log("👍", "beforeinstallprompt", event);
    // Stash the event so it can be triggered later.
    window.deferredPrompt = event;
    // Remove the 'hidden' class from the install button container.
    setIsReadyForInstall(true);
  });
}, []);

    //Eventos de open y close menu
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () =>{
        handleMenuClose();
        dispatch(logout());
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handledUserProfile = () => {
        history.push("/user-profile")
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
        
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
            <hr />
            <MenuItem onClick={handledUserProfile}>Perfil</MenuItem>
            <MenuItem onClick={handleLogout}>Salir</MenuItem>
        </Menu>
    );

    const MobileMenuId = 'primary-search-account-menu-mobile';

    return (
        <>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    {/* <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {title}
                    </Typography>
                    {isReadyForInstall &&  <div>
                    
                    <Grid justify='right'>
                            
                            <Button style={{background:"#ffffff"}} onClick={downloadApp} >
                                Descargar PWA
                            </Button>
                       
                    </Grid>
                            </div>}
                    <div className={classes.sectionDesktop}>
                        <MenuDesktop
                            menuId={menuId}
                            handleProfileMenuOpen={handleProfileMenuOpen}
                            numNotifications={numNotifications}
                        />
                    </div>
                   
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={MobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <MenuMobile
                handleProfileMenuOpen={handleProfileMenuOpen}
                isMobileMenuOpen={isMobileMenuOpen}
                handleMobileMenuClose={handleMobileMenuClose}
                mobileMoreAnchorEl={mobileMoreAnchorEl}
                MobileMenuId={MobileMenuId}
                numNotifications={numNotifications}
            />
            {renderMenu}
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <ListMenuItem  _menus={_menus}/>
                {/* <Divider />
                <List>{mainListItems}</List>
                <Divider />
                <List>{secondaryListItems}</List> */}
            </Drawer>
        </>
    );
}

export default Header;