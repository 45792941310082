import React from "react";
import { useEffect, useState } from "react";


const RangeigcSVG = ({ percentage, setColorRango, Rango }) => {
  const [formattedPercentage, setFormattedPercentage] = useState("0.0");
  const [rangePosition, setRangePosition] = useState(-1);

  useEffect(() => {
    const newFormattedPercentage = (percentage || percentage === 0) ? percentage.toFixed(1) : "0.0";
    setFormattedPercentage(newFormattedPercentage);
    const position = getPositionForRange(Rango);
    setColorRango(getColorForPosition(position));
    setRangePosition(position);
  }, [percentage, Rango]);

  const customRanges = [
    { color: "#1983c4", label: "Bajo", min: 0, max: 21 },
    { color: "#005aa9", label: "Normal", min: 21, max: 33 },
    { color: "#48457c", label: "Alto", min: 33, max: 39 },
    { color: "#a92a41", label: "Muy Alto", min: 39, max: 100 },
  ];

  // Encuentra la posición del rango correspondiente según el valor de Rango
  const getPositionForRange = (rangeName) => {
    const matchingRange = customRanges.find((range) => range.label === rangeName);
    return matchingRange ? customRanges.indexOf(matchingRange) : -1;
  };

  // Obtiene el color correspondiente a una posición
  const getColorForPosition = (position) => {
    return position !== -1 ? customRanges[position].color : "black"; // Default color if position is not found
  };



  const borderRadius = 8;
  const sectionWidth = 53;
  const sectionHeight = 0;
  const marginTop = 20;
  const totalWidth = customRanges.length * sectionWidth;
  const totalHeight = sectionHeight + marginTop;




  let textX = 0;
  if (rangePosition !== -1) {
    textX = rangePosition * sectionWidth - 10 + sectionWidth / 2;
  }


  return (
    <div>
      <svg width={totalWidth} height={totalHeight} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="clipPath">
            <rect width={totalWidth} height={totalHeight} rx={borderRadius} ry={borderRadius} />
          </clipPath>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "white", stopOpacity: 0 }} />
            <stop offset="10%" style={{ stopColor: "white", stopOpacity: 1 }} />
            <stop offset="90%" style={{ stopColor: "white", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "white", stopOpacity: 0 }} />
          </linearGradient>
        </defs>

        <rect
          x="0"
          y={marginTop}
          width={totalWidth}
          height={sectionHeight}
          fill={`url(#gradient)`}
          rx={borderRadius}
          ry={borderRadius}
        />

        {customRanges.map((item, index) => (
          <g key={index}>
            <rect
              x={index * sectionWidth}
              y={marginTop}
              width={sectionWidth}
              height={sectionHeight}
              fill={item.color}
              clipPath="url(#clipPath)"
            />
          </g>
        ))}
      </svg>
      {rangePosition !== -1 && (
        <div style={{ position: "relative", textAlign: "center" }}>
          <div style={{ position: "absolute", left: textX + 65, bottom: 3 }}>
            <span style={{ color: customRanges[rangePosition].color, fontWeight: "bold" }}>{`${formattedPercentage}%`}</span>
          </div>
          <div style={{ position: "absolute", left: textX + 70, top: -15 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 20 20">
              <polygon fill={customRanges[rangePosition].color} points="10,20 20,0 0,0" />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeigcSVG;
