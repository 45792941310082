import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, CardActions, CardContent, CardHeader, Collapse, Tooltip, Typography,Container } from '@material-ui/core';
import Title from '../Title';
import Card from '@material-ui/core/Card';
import InfoIcon from '@material-ui/icons/Info';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Button, Breadcrumbs } from '@material-ui/core';
import { ImcSlide } from '../../Imc/ImcInfo';
import { Dashboard } from '../../../../src/dashboard/index';
import { menus as menuBascula } from '../../../centraldevida/menu/menuJson';
import Header from '../../../dashboard/componet/AppBarOrs/Header';

import { PlusInfo } from '../../../orussystem/utils/plusInfo';

//icon images
/* import iconMen from '../../../img/icon_men.svg';
import iconWomen from '../../../img/icon_woman.svg';
import iconNull from '../../../img/icon_null.svg';
import tableInfo from '../../../img/tabla_central_vida.svg'
import imgColorsSlide from "../../../img/colores_termometro_320.svg" */
import '../../../Styles/stylesPages.css'
import imgImc from '../images/IMC.png';
import background from '../images/Background2.png'



const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },
    root: {
        minWidth: 275,
       
    },
    media: {
        height: 0,
        paddingTop: '45.25%', // 16:9
        paddingLeft: '45.25%',
        paddingRight: '45.25%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 15,
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    avatarCunstom: {
        textAlign: "left",
        margin: "0 auto",
        // borderRadius: "50%",
        // backgroundColor: "red",
        // backgroundImage: `url(${iconMen})`,
        backgroundRepeat: "no-repeat",
        color: "white",
        height: "70px",
        maxWidth: "70px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    boxStates: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
        marginTop: "2px",
        backgroundColor: "#e3e3e3",
        color: "#1c1c1d",
        border: "1px solid #b3b3b3",
        fontSize: 20,
        fontWeight: "bold"
    },
    alertImc: {
        fontSize: 20,
        backgroundColor: "black",
        margin: "0 auto",
        maxWidth: "80%",
        marginTop: "25px",
        textAlign: "center",
        borderRadius: "20PX",
        fontWeight: "bold",
        padding: 4
    }
}));




export default function InfoImc({ value, data, genero, edad, lIcms}) {
  const menu =  menuBascula();

    const classes = useStyles();
    // let { imc } = useParams();
    const [imcValue, setImcValue] = useState("")

    const handledColor = () => {
        if (imcValue < 18.5) {
            return "#41c8f5"
        } else if (imcValue >= 18.5 && imcValue <= 24.9) {
            return "#2bb673"
        } else if (imcValue >= 25 && imcValue <= 29.9) {
            return "#fcd704"
        } else if (imcValue >= 30) {
            return "#ec1f26"
        }
    }

    const handledLable = () => {
        if (imcValue < 18.5) {
            return "Bajo de peso"
        } else if (imcValue >= 18.5 && imcValue <= 24.9) {
            return "Peso normal"
        } else if (imcValue >= 25 && imcValue <= 29.9) {
            return "Sobrepeso"
        } else if (imcValue >= 30 && imcValue <= 34.9) {
            return "Obesidad 1°"
        } else if (imcValue >= 35 && imcValue <= 39.9) {
            return "Obesidad 2°"
        } else if (imcValue >= 40) {
            return "Obesidad 3°"
        }
    }




    useEffect(() => {
        setImcValue(value)
    }, [value])



    return (
        <>
                <Container className={classes.container} >
                {/* <Header title={process.env.REACT_APP_TITLE} _menus={menu} /> */}


                    <Typography align="center" className={classes.title} color="textSecondary" gutterBottom  >
                        <CardContent >
                            <Grid container spacing={4}>
                                <Grid item xs={3} sm={3} md={3}>
{/*                                     <div className={classes.avatarCunstom} style={{ backgroundImage: `url(${genero === undefined || genero === null || genero === "" ? iconNull : genero !== "F" ? iconMen : iconWomen})`, }}></div>
 */}                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <label style={{fontSize:"12px"}}>Edad (años)</label>
                                    <div className={classes.boxStates} id="label-div">
                                        {edad}
                                    </div>
                                </Grid>
                                {data.map((item, key) =>
                                    <Grid item xs={3} sm={3} md={3} id={key}>
                                        <label style={{fontSize:"12px"}}>{item.label}</label>
                                        <div className={classes.boxStates} id="label-div">
                                            {item.value}
                                        </div>
                                    </Grid>

                                )}
                            </Grid>
                        </CardContent>
                        <div style={{marginBottom: -15}}><Title align="center" >Índice de Masa Corporal</Title></div>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
{/*                                     <ImcSlide imc={imcValue} max={40} min={15} imgSelect={imgColorsSlide}/>
 */}                                </Grid>
                            </Grid>

                        </CardContent>
                        <div className={classes.alertImc} style={{ color: handledColor() }}>{handledLable()}</div>

                        <PlusInfo render={
                            <Typography align="center" className={classes.title} color="textSecondary" gutterBottom>
                                {/* <Title >Tabla</Title>
                                {/* <div><img src={tableInfo} style={{ maxWidth: "254px", maxHeight: "311px" }}></img></div> */}
                                <Title >Índice de masa corporal</Title>
                                <p className={classes.title}>
                                    Una medida de la obesidad se determina mediante el índice de masa corporal (IMC), que se calcula dividiendo
                                    los kilogramos de peso por el cuadrado de la estatura en metros (IMC = peso [kg]/ estatura [m2]).
                                </p>
                                <Title >¿Tengo sobre peso?</Title>
                                <p>
                                    Según el Instituto Nacional del Corazón, los Pulmones y la Sangre de los Estados Unidos (NHLBI), el sobrepeso se define como un IMC de más de 25. Se considera que una persona es obesa si su IMC es superior a 30.
                                </p> 
                                
                            </Typography>

                        } watch={"/info-imc"} disabled={false} />
                        
                    </Typography>
                  

                </Container>
            




        </>
    );
}