import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import './footerStyles.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },

    borderRadius: '60px'

  },
  buttoninactive: {
    color: "#E9E9EC"
  },

  buttonactive: {
    color: "primary"
  }
}));



export default function TusDatos({ edad, peso, altura, key, textStyle }) {
  const classes = useStyles();
  const [active, setActive] = React.useState(false);

  return (

    <div className={key = 'imc' ? 'button-group' : 'button-group2'}>
      <button className="button1">
        <div className="number" style={textStyle}><b>{edad ? edad === "NaN" ? "-" : edad : 0}</b></div>
        <div className="text" style={textStyle}>Edad (años)</div>
      </button>
      <button className="button">
        <div className="number" style={textStyle}><b>{peso ? peso === "NaN" ? "-" : peso : 0}</b></div>
        <div className="text" style={textStyle}>Peso(Kg)</div>
      </button>
      <button className="button3">
        <div className="number" style={textStyle}><b>{altura ? altura === "NaN" ? "-" : altura : 0}</b></div>
        <div className="text" style={textStyle}>Altura(m)</div>
      </button>
    </div>

  );
}