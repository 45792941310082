import React, { useState } from 'react';
import {
    Paper,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    Button,
    Container,
    CircularProgress,
    Modal,
    Box


} from '@material-ui/core';
import '../../../Styles/index.css';
import clsx from 'clsx';
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { useStyles } from './SinginStyles';
import { changeOpt } from '../../domain/userSlice';
import theme from '../../../app/config/themaConfig';


export default function ForgotPasswordForm({ setShowForgot, setShowRecuperar }) {

    const classes = useStyles();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const dispatch = useDispatch();
    const [documento, setDocumento] = useState("");
    const [celular, setCelular] = useState("");
    const [selectedOption, setSelectedOption] = useState("sms");


    const enviar = async () => {
        try {
            let request = {
                type: selectedOption,
                to: celular,
                country: "57",
                document: documento
            };

            const respuesta = await changeOpt(dispatch, request);

            if (respuesta.message = "exitoso") {
                setShowRecuperar(true);
                setShowForgot(false);
            } else {
                alert('Hubo un error al enviar el codigo. Inténtalo de nuevo más tarde.');
                setCelular("");
                setDocumento("");
            }
        } catch (error) {

            console.error(error);
        }
    };
    const primaryColor = theme.palette.primary.main;
    const inputStyles = {
        border: `1px solid ${primaryColor}`, // Usa el color primario del tema para el borde
        color: 'black',

    };

    return (
        <div className={classes.centerv}>
            <div className='containerlogin'>
                <Paper className={borderRadius}>
                    <div className={classes.margin}>
                        <Grid container justify="center" style={{ marginTop: '15px' }}>
                            <img src='https://centraldevida.s3.amazonaws.com/Logos/EVBI+-+Logo__3.png' width="120" alt="Logo" />
                        </Grid>
                        <div className='textoRecuperar' style={{ color: primaryColor }}>
                            Por favor ingresa tu documento y el número celular que registraste en EVBI.
                        </div>
                        <form >
                            <Grid container spacing={8} alignItems="flex-end" style={{ marginTop: '15px' }}>


                                <Grid item md={true} sm={true} xs={true} style={{ padding: '32px 32px 32px 0px' }}>
                                    <input
                                        placeholder="Documento de identidad "
                                        label="Celular"
                                        fullWidth
                                        name="cel"
                                        type="tel"
                                        value={documento}
                                        onChange={(e) => setDocumento(e.target.value)}
                                        required='ingresa tu documento de identidad'
                                        style={inputStyles}

                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={8} alignItems="flex-end" style={{ marginTop: '-15px' }}>


                                <Grid item md={true} sm={true} xs={true} style={{ padding: '14px 32px 32px 0px' }}>
                                    <input
                                        placeholder="Telefono registrado"
                                        label="Celular"
                                        fullWidth
                                        name="cel"
                                        type="tel"
                                        value={celular}
                                        onChange={(e) => setCelular(e.target.value)}
                                        required='ingresa tu numero de celular'
                                        style={inputStyles}

                                    />
                                </Grid>
                            </Grid>

                            <div className='textoRecuperar' style={{ color: primaryColor }}>
                                ¿como deseas recibir tu codigo?
                            </div>
                            <Grid container justifyContent="space-between" style={{ marginTop: '20px' }}>
                                <Box sx={{ width: '80%' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            textTransform: 'none',
                                            width: '120%',
                                            marginLeft: "52%",
                                            paddingLeft: "23%",
                                            height: '28px',
                                            backgroundColor: selectedOption === "WSPT" ? primaryColor : 'white',
                                            color: selectedOption === "WSPT" ? 'white' : primaryColor,
                                            borderRadius: '10px',
                                            border: `1px solid ${primaryColor}`
                                        }}
                                        onClick={() => setSelectedOption("WSPT")}
                                    >
                                        <BsWhatsapp /> WhatsApp
                                    </Button>

                                </Box>
                                <Box sx={{ width: '80%' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            textTransform: 'none',
                                            width: '139%',
                                            marginLeft: "95%",
                                            paddingLeft: "23%",
                                            height: '28px',
                                            backgroundColor: selectedOption === "SMS" ? primaryColor : 'white',
                                            color: selectedOption === "SMS" ? 'white' : primaryColor,
                                            borderRadius: '10px',
                                            border: `1px solid ${primaryColor}`
                                        }}
                                        onClick={() => setSelectedOption("SMS")}

                                    >
                                        <AiOutlineMessage /> Mensaje
                                    </Button>
                                </Box>
                            </Grid>
                            <div className={classes.wrapper}>
                                <Grid container justifyContent="space-between" style={{ marginTop: '35px' }}>
                                    <Box sx={{ width: '80%' }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                textTransform: 'none', width: '139%', marginLeft: "40%", borderRadius: '10px',
                                                marginTop: '5%',
                                                height: '30px'
                                            }}
                                            onClick={() => setShowForgot(false)}
                                        >

                                            Cancelar
                                        </Button>
                                    </Box>
                                    <Box sx={{ width: '80%' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                textTransform: 'none', width: '171%', marginLeft: "120%", borderRadius: '10px',
                                                marginTop: '5%',
                                                height: '30px'
                                            }}
                                            onClick={() => enviar()}
                                        >
                                            Enviar
                                        </Button>

                                    </Box>
                                    <b className='versionrecuperar' style={{ color: primaryColor }}>{`${process.env.REACT_APP_VERSION}`}</b>
                                </Grid>
                                <Grid container justify="center" style={{ marginTop: '20px' }}>

                                </Grid>

                            </div>
                        </form>
                    </div>
                </Paper>
            </div>


        </div>
    );
}
