import React from 'react';

import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';

import clsx from 'clsx';

import Chart from './Chart';
import Orders from './Orders';
import Deposits from './Deposits';

import { ImcPortalStyle } from './ImcPortalStyle';

export const Imc = (props) => {

  const classes = ImcPortalStyle();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


  return (
    <>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightPaper}>
          <Chart />
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>
          <Deposits />
        </Paper>
      </Grid>

      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Orders />
        </Paper>
      </Grid>
    </>
  );
}
