import React, { useEffect } from "react";

const Rectangle = () => {
  const colors = [
    { color: "#1983c4", label: "Bajo", min: 0, max: 18.5 },
    { color: "#005aa9", label: "Normal", min: 18.5, max: 25 },
    { color: "#48457c", label: "SobrePeso", min: 25, max: 30 },
    { color: "#79385f", label: "Obesidad clase l", min: 30, max: 35 },
    { color: "#a92a41", label: "Obesidad clase ll", min: 35, max: 40 },
    { color: "#d91c23", label: "Obesidad clase lll", min: 40, max: 1000 },
  ];

  const borderRadius = 8;
  const sectionWidth = 53;
  const sectionHeight = 20;
  const totalWidth = colors.length * sectionWidth;

  useEffect(() => {
    const rangeLocations = colors.map((item, index) => {
      const rangeStart = index * sectionWidth;
      const rangeEnd = rangeStart + sectionWidth;
      return { label: item.label, min: item.min, max: item.max, start: rangeStart, end: rangeEnd };
    });

  }, []);

  return (
    <svg width={totalWidth} height={sectionHeight} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clipPath">
          <rect width={totalWidth} height={sectionHeight} rx={borderRadius} ry={borderRadius} />
        </clipPath>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: "white", stopOpacity: 0 }} />
          <stop offset="10%" style={{ stopColor: "white", stopOpacity: 1 }} />
          <stop offset="90%" style={{ stopColor: "white", stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: "white", stopOpacity: 0 }} />
        </linearGradient>
      </defs>

      <rect
        x="0"
        y="0"
        width={totalWidth}
        height={sectionHeight}
        fill={`url(#gradient)`}
        rx={borderRadius}
        ry={borderRadius}
      />

      {colors.map((item, index) => (
        <g key={index}>
          <rect
            x={index * sectionWidth}
            y="0"
            width={sectionWidth}
            height={sectionHeight}
            fill={item.color}
            clipPath="url(#clipPath)"
          />
          <text
            x={index * sectionWidth + sectionWidth / 2}
            y="10"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
            fontSize="10"
            fontWeight="bold"

          >
            {`${item.min}-${item.max}`}
          </text>
        </g>
      ))}
    </svg>
  );
};

export default Rectangle;
