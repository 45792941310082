import React from 'react';
import ApexCharts from 'react-apexcharts';

const GraficaArea = ({ data, DataRangos }) => {

  const last5Data = data.slice(-5).reverse();
  const imcValues = last5Data.map((record) => parseFloat(record.imc).toFixed(0));
  // Meses correspondientes a los puntos en el eje X
  const months = last5Data.map((record) => {
    const date = new Date(record.datecreate);
    const day = date.getDate();
    const monthNames = [
      "ene", "feb", "mar", "abr", "may", "jun",
      "jul", "ago", "sep", "oct", "nov", "dic"
    ];
    const month = monthNames[date.getMonth()];
    return `${day} ${month}`;
  });


  const findIMCRange = (imc) => {
    for (const rango of DataRangos) {
      const { parametros, valor } = rango;

      if (parametros) {
        const parsedParametros = JSON.parse(parametros);

        if (parsedParametros && parsedParametros.imc) {
          const { imc: imcRange } = parsedParametros;
          const [min, max] = imcRange.split('-').map(parseFloat);

          if (!isNaN(min) && !isNaN(max) && imc >= min && imc <= max) {

            return valor;
          }
        }
      }
    }
    return '';
  };
  const imcRangeLabels = imcValues.map(imc => findIMCRange(imc));

  const options = {
    chart: {
      height: 100,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [20, 30, 40],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: months, // Usa las últimas 5 fechas en el eje x
    },
    yaxis: {
      title: {
        text: 'IMC',
        style: {
          color: '#1983c4',
          fontfamily: 'Segoe UI Variable Static Small 700',
        }
      },
      min: 15, // Valor mínimo en el eje y
      max: 40, // Valor máximo en el eje y
      formatter: function (value) {
        return parseInt(value);
      },
    },
    tooltip: {
      y: {
        formatter: function (value, { series, dataPointIndex }) {
          const formattedIMC = imcRangeLabels[dataPointIndex] ? imcRangeLabels[dataPointIndex] : typeof series[dataPointIndex] === 'number' ? series[dataPointIndex].toFixed(1) : '-';
          return `IMC : ${formattedIMC}`;
        },
        title: {
          formatter: () => "",
        },
      },
    },
    stroke: {
      width: 3,
      colors: ["#005aa9"], 
      opacity: 3,
  },
  markers: {
      size: 4,
      colors: '#005aa9', 
  },
  };

  return (
    <div className="grafica-area">
      <ApexCharts options={options} series={[{ data: imcValues  }]} type="area" height={280} />
    </div>
  );
};

export default GraficaArea;