import React, { useState } from 'react'
import { Style } from '../PollStyle';
import clsx from 'clsx';
import { Grid, Paper, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Title from '../../../orussystem/Conponent/Title';

export const ResponseQuestionPublic = ({ quetion, size, index, type, response}) => {
    
    const classes = Style();
    const fixedHeightPoll = clsx(classes.paper, classes.fixedHeight);
    const [answers, setAnswers] = useState(quetion.answers);

    return (
        <>
            <Grid item xs={12}>
                <Paper className={fixedHeightPoll}>
                    <Grid item xs={12} sm={6} >
                        <Title component="h1">Pregunta {index + 1}/{size}</Title>
                        <h3>{quetion.question}</h3>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        {type === "C" && <FormControl component="fieldset">
                            <FormLabel component="legend">Respuestas</FormLabel>
                            <RadioGroup aria-label={index} name={index} /*value={response.answer.answer}*/>
                                {
                                    answers.map(a => {
                                        return <FormControlLabel key={a.id} value={a.answer} control={<Radio />} label={a.answer} />
                                    })
                                }
                            </RadioGroup>
                        </FormControl>}
                        {type !== "C" &&
                            <TextField
                                id="response"
                                label="Respuesta"
                                fullWidth
                                multiline
                                //value={response.detalle}

                            />
                        }
                    </Grid>
                </Paper>
            </Grid>

        </>
    )
}