import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Grid, FormControl, InputAdornment, InputLabel, OutlinedInput, CircularProgress, Select, MenuItem } from "@material-ui/core";
import { StyleUser } from "../styleUser";
import { useDispatch } from "react-redux";
import { updateUserPerfil } from "../../domain/api-client/UserMachine";
import { useHistory } from "react-router-dom";
import { Store } from "react-notifications-component";

export const FormUser = ({ data }) => {

    const classes = StyleUser();
    const [isLoanding, setisLoanding] = useState(false);
    const [isTrueSend, setisTrueSend] = useState(false)
    const [messageSend, setMessageSend] = useState("")
    const [color, setColor] = useState("")
    const dispatch = useDispatch();
    const history = useHistory()

    const setalertSend = (msj, color) => {
        setisTrueSend(true)
        setMessageSend(msj);
        setColor(color)
        setTimeout(() => {
            setisTrueSend(false)
        }, 3000);
    }
    const changeFormatDate = (date) => {
        let fecha = new Date(date);
        let año = fecha.getFullYear();
        let mes = fecha.getMonth() + 1;
        let day = fecha.getDate();
        mes = mes < 10 ? `0${mes}` : mes;
        day = day < 10 ? `0${day}` : day;
        fecha = `${año}-${mes}-${day}`

        return fecha
    }


    return (
        <Formik

            initialValues={{
                genero: data.genero,
                altura: data.altura,
                fechaNacimiento: changeFormatDate(data.edad),
            }}

            validate={(valores) => {

                let errores = {};

                if (!valores.genero) {
                    errores.genero = 'Por favor ingresa un genero'
                } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.genero)) {
                    errores.genero = 'No se aceptan caracteres especiales ni números'
                }

                if (!valores.altura) {
                    errores.altura = 'Por favor ingresa una altura'
                }
                // else if (!/^[0-9]+\.[0-9]`$/.test(valores.altura)) {
                //     errores.altura = 'No se aceptan caracteres especiales ni letras'
                // }

                if (!valores.fechaNacimiento) {
                    errores.fechaNacimiento = 'Por favor ingresa una fecha valida'
                } else if (!/^\d{4}([\-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/.test(valores.fechaNacimiento)) {
                    errores.fechaNacimiento = 'No se aceptan caracteres especiales ni números'
                }

                return errores;

            }}

            onSubmit={(valores, { resetForm }) => {

                setisLoanding(true)

                const request = {
                    id: data.id,
                    edad: valores.fechaNacimiento,
                    genero: valores.genero,
                    altura: valores.altura
                }

                updateUserPerfil(dispatch, request).then((res) => {
                    // setalertSend("Datos actualizados con exito", "#2bb673");

                    Store.addNotification({
                        title: "Exito!",
                        message: `Datos actualizados con exito`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });

                    history.push("/")


                }).catch((err) => {

                    Store.addNotification({
                        title: "Error interno!",
                        message: `Los datos no se han podido actualizar`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                })

                setTimeout(() => {
                    setisLoanding(false)
                }, 2000);

                resetForm();
            }}
        >
            {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} style={{ textAlign: "left" }}>
                        <Grid item xs={12} md={12} sm={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="genero">Genero</InputLabel>
                                <Select
                                    id="genero"
                                    type="text"
                                    name='genero'
                                    defaultValue={values.genero}
                                    value={values.genero}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    startAdornment={<InputAdornment position="start">-</InputAdornment>}
                                    labelWidth={60}
                                >
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    <MenuItem value="M">Masculino</MenuItem>
                                    <MenuItem value="F">Femenino</MenuItem>

                                </Select>
                            </FormControl>
                            {touched.genero && errors.genero && <div style={{ color: "red" }}>{errors.genero}</div>}
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="genero">Altura</InputLabel>
                                <OutlinedInput
                                    id="altura"
                                    type="text"
                                    name='altura'
                                    defaultValue={values.altura}
                                    value={values.altura}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    endAdornment={<InputAdornment position="start">Mt</InputAdornment>}
                                    startAdornment={<InputAdornment position="start">-</InputAdornment>}
                                    labelWidth={60}
                                />
                            </FormControl>
                            {touched.altura && errors.altura && <div style={{ color: "red" }}>{errors.altura}</div>}
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="genero">Fecha Nacimiento</InputLabel>
                                <OutlinedInput
                                    id="fechaNacimiento"
                                    type="date"
                                    name='fechaNacimiento'
                                    defaultValue={values.fechaNacimiento}
                                    value={values.fechaNacimiento}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    startAdornment={<InputAdornment position="start">-</InputAdornment>}
                                    labelWidth={140}
                                />
                            </FormControl>
                            {touched.fechaNacimiento && errors.fechaNacimiento && <div style={{ color: "red" }}>{errors.fechaNacimiento}</div>}
                        </Grid>
                    </Grid>
                    <div className={classes.wrapper}>
                        <Grid container justify="center" style={{ marginTop: '10px' }}>
                            <Button
                                disabled={isLoanding}
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ textTransform: "none", width: "100%" }}
                            >
                                Actulizar usuario
                            </Button>
                        </Grid>
                        {isLoanding && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    {isTrueSend && <div style={{ color: color, fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>{messageSend}</div>}
                </form>
            )}
        </Formik>
    );
}