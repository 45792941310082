import { useState } from 'react';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import { logout } from '../../../user/domain/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { BiUserCircle } from "react-icons/bi";
import './../../../Styles/Perfil.css';
import { IoLogOutOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";

export default function DatosUsuarioPerfil({ handleComponentRango, handleComponentChange, textStyle }) {

  const [showLogout, setShowLogout] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  }

  const handleButtonClick = (accion, key) => {
    handleComponentChange(accion);
    handleComponentRango(key)

  };

  return (
    <>
      <Stack direction="row" width="200px" spacing={2}>
        <h1>
          <div className='perfil'>Perfil</div>
        </h1>
        <div className="containavatar2">
          <BiUserCircle className='icon' onClick={() => setShowDropdown(!showDropdown)} />
          {showDropdown && (
            <div className="option-list" >
              <button className="option-button"
               onClick={() => handleButtonClick('Container11', 'imc')}
              style={textStyle}>
                <MdEdit className='iconlogout2'></MdEdit>Editar
              </button>
              <button className="option-button" onClick={handleLogout} style={textStyle}>
                <IoLogOutOutline className='iconlogout2'></IoLogOutOutline>Cerrar sesión
              </button>
            </div>
          )}
        </div>
      </Stack>
    </>
  );
}
