import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, AreaChart } from 'recharts';
import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    LabelList,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import Title from '../../../Title';
import { UtilsDate } from '../../../../../orussystem/utils/UtilDate';
import { Bar } from 'recharts';
import { BarChart } from 'recharts';

import { CartesianGrid } from 'recharts';
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(15),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
// Generate Sales Data
function createData(time, amount) {
    return { time, amount };
}


export default function ChartIGC({ dataPGC, generoUser }) {

    const theme = useTheme();
    const history = useHistory()
    const l = dataPGC !== undefined ? dataPGC.length == 1 ? [...dataPGC, ...dataPGC] : dataPGC : []
    const dataIgc = dataPGC.map(igc => {

        let igcal = Math.round(igc.igc * 100) / 100
        let genero = generoUser

        return ({
            id: igc.id,
            name: UtilsDate().formatDDMM(new Date(igc.datecreate)),
            bajo: genero == 'M' ? igcal <= 11 ? igcal : 0 : igcal <= 21 ? igcal : 0,
            normal: genero == 'M' ? igcal >= 11 && igcal <= 22 ? igcal : 0 : igcal >= 21 && igcal <= 33 ? igcal : 0,
            alta: genero == 'M' ? igcal >= 22 && igcal <= 28 ? igcal : 0 : igcal >= 33 && igcal <= 39 ? igcal : 0,
            muyalta: genero == 'M' ? igcal > 28 ? igcal : 0 : igcal > 39 ? igcal : 0,
        })
    })

    const goBack = () => {
        history.push("/")
    }


    return (
        <>
            <Title>Porcentaje de Grasa Corporal</Title>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            layout="vertical"
                            width={500}
                            height={300}
                            data={dataIgc}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                        >

                            <XAxis type="number" unit="%" />
                            <YAxis dataKey="name" type="category" scale="band" />

                            <Bar dataKey="bajo" stackId="a" fill="#0088FE" />
                            <Bar dataKey="normal" stackId="a" fill="#2bb673" />
                            <Bar dataKey="alta" stackId="a" fill="#fcd704" />
                            <Bar dataKey="muyalta" stackId="a" fill="#ec1f26" />
                        </ComposedChart>
                    </ResponsiveContainer>
         
            <Grid container spacing={2} style={{ padding: 0, marginTop: '10px', marginLeft: '1px', }}>
                        <Grid item xs={6}>
                            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <FiberManualRecordIcon style={{ color: "#41c8f5" }} />
                                    </Grid>

                                    <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Bajo</Grid>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography>Descripción</Typography>
                                                    <b><br />{"Bajo: "}</b>{' '}
                                                    {"HOMBRES con valores menor a 11% y MUJERES con valores menores al 21%."}
                                                </React.Fragment>
                                            }
                                            placement="right-start"
                                        >
                                            <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                        </HtmlTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <FiberManualRecordIcon style={{ color: "#41c8f5" }} />
                                    </Grid>

                                    <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Normal</Grid>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography>Descripción</Typography>
                                                    <b><br />{"Normal: "}</b>{' '}
                                                       {"HOMBRES con valores entre 11% y 22% y MUJERES con valores entre 21% y 33%."}
                                                </React.Fragment>
                                            }
                                            placement="right-start"
                                        >
                                            <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                        </HtmlTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <FiberManualRecordIcon style={{ color:"#fcd704"  }} />
                                    </Grid>

                                    <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Alta</Grid>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography>Descripción</Typography>
                                                    <b><br />{"Alta: "}</b>{' '}
                                            {"HOMBRES con valores entre 22% y 28% y MUJERES con valores entre 33% y 39%."}
                                                </React.Fragment>
                                            }
                                            placement="right-start"
                                        >
                                            <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                        </HtmlTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <FiberManualRecordIcon style={{ color:"#ec1f26" }} />
                                    </Grid>

                                    <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Muy Alta</Grid>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography>Descripción</Typography>
                                                    <b><br />{"Muy Alta:"}</b>{' '}
                                            {"HOMBRES con valores mayor a 28% y MUJERES con valores mayor a 39%."}
                                                </React.Fragment>
                                            }
                                            placement="right-start"
                                        >
                                            <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                        </HtmlTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none", width: "inherit", marginTop: 50 }}
                onClick={goBack}
            >
                Volver
            </Button>
        </>
    );
}

