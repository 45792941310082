import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import { selectUser } from '../../../user/domain/userSlice';
import { Style } from '../PollStyle';

import QuestionPublic from './QuestionPublica';
import { ResponseQuestionPublic } from './ResponseQuestionPublic'
import Title from '../../../orussystem/Conponent/Title';
import { findQuestionByUserAndPoll, getQuetiosPublic, setPollDataRequesPublic, setPollDataRequest } from '../../domain/api-client/UserMachine'

import { Alert } from '@material-ui/lab';
import {
    Paper,
    Typography,
    Grid,
    TextField,
    Button,
    Container, CircularProgress, IconButton
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { USER_ID } from '../../../app/api-client/constants';


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));



export const PanelQuestionPublic = () => {

    let { id } = useParams();
    id = id === ":id" || id === "" ? 0 : id;

    const id_user_login = localStorage.getItem(USER_ID)

    const classes = Style();
    const fixedHeightPoll = clsx(classes.paper, classes.fixedHeight);
    //const classes = useStyles();
    const [data, setData] = useState([]);
    const [dataRequest, setDataRequest] = useState([]);
    const [isLoadData, setIsLoadData] = useState(false);
    const [isLoandButton, setIsLoandButton] = useState(false);
    const [dataResponse, setDataResponse] = useState([]);
    const [isResponse, setIsResponse] = useState(false);
    const [isErr, setisErr] = useState(false)

    const [open, setOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    let history = useHistory();
    const userAuth = useSelector(selectUser);

    const body = (
        <div style={modalStyle} className={classes.paper}>
            {isErr ? <h2 id="simple-modal-title">Error...</h2> : <h2 id="simple-modal-title">Gracias...</h2>}
            {isErr
                ? <p id="simple-modal-description">
                    Es importante que contestes toda la encuesta
                </p>
                : <p id="simple-modal-description">
                    Los datos fueron enviados.
                </p>

            }
            <Button
                onClick={(e) => { isErr ? handledCloseIsErr() : handleClose() }}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}>
                Cerrar
            </Button>

        </div>
    );


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        history.push("/");
    };

    const goBack = () => {
        setOpen(false);
        history.push("/Encuestas");
    }

    const handledCloseIsErr = () => {
        setOpen(false);
        setisErr(false)
    }

    const user = useSelector(selectUser);

    useEffect(async () => {
        const polls = await getQuetiosPublic(11);
        setData(polls.content);
        setIsLoadData(true);
        const userAnswer = await findQuestionByUserAndPoll(id_user_login, id);
        setDataResponse(userAnswer.code === '0000' ? [] : userAnswer.userBasAnswers)
        setIsResponse(userAnswer.code === '0001')
    }, []);


    const sendResult = async (event) => {
        setIsLoandButton(true);
        let count = 0;
        const ans = dataRequest.map(dr => {
            if (dr.value !== "" && dr.value !== null && dr.value !== undefined) {
                count += 1;
            }
            return dr.value
        })

        if (data.length === count) {
            setisErr(false)
            const sendData = {
                poll: { id: 2 },
                answers: ans
            }
            const response = await setPollDataRequesPublic(id_user_login, sendData);
            handleOpen();
            setIsLoandButton(false);
        }
        else {
            setisErr(true);
            handleOpen();
            setIsLoandButton(false);
        }
    };

    const handleCheck = (check, id) => {
        const tem = [...dataRequest.filter(d => d.id != id), { id, value: check }]
        setDataRequest(tem);
    }

    const recordTowArray = () => {
        for (let i; i < data.length; i++) {
            return <ResponseQuestionPublic key={i} index={i} size={data.length} quetion={data[i]} type={data[i].type.toUpperCase()} /*response={dataResponse[i].detalle}*/ />
        }
    }

    return (
        <>
            <Grid align="center" item xs={12} >
                <Title component="h1">{`${!isResponse ? "Encuestas nueva" : "Responder Encuesta"}`}</Title>
            </Grid>
            <Grid item xs={12}>
                <Paper className={fixedHeightPoll} >
                    <Grid item xs={12} sm={6} >
                        <Title component="h1">Pregunta</Title>
                        <h3>Digita tu número movil</h3>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextField
                            id="response"
                            label="Respuesta"
                            fullWidth
                            multiline
                        />
                    </Grid>
                </Paper>
            </Grid>
            {
                !isLoadData ? <>Cargando ...</>
                    : !isResponse
                        ? data.map((q, index) => {

                            return <QuestionPublic key={index} index={index} size={data.length} quetion={q} type={q.type.toUpperCase()} handleCheck={handleCheck} />
                        })
                        : data.map((q, index) => {

                            return <ResponseQuestionPublic key={index} index={index} size={data.length} quetion={q} type={q.type.toUpperCase()} response={dataResponse[index]} />
                        })
            }

            <Grid align="center" item xs={12} >
                <div className={classes.wrapper}>
                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                        {isResponse != null && <Button
                            disabled={isLoandButton}
                            onClick={(e) => { sendResult(e) }}
                            variant="contained"
                            color="primary"
                            style={{ textTransform: "none", width: "inherit" }}>
                            Enviar Datos
                        </Button>}

                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ textTransform: "none", width: "inherit", marginTop: 20 }}
                            onClick={(e) => { goBack() }}
                        >
                            Volver
                        </Button>

                    </Grid>
                    {isLoandButton && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </Grid>
            <br />
            {isErr && <div style={{ color: "red", fontSize: "13px", margin: "0 auto" }}>Por favor conteste todas las preguntas</div>}

            <Modal
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

        </>
    );
}