import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Container,
  CircularProgress,
  Link,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import '../../../Styles/index.css';
import { Alert } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha'; // Importa el componente ReCAPTCHA
import { useStyles } from './SinginStyles';
import {
  postLogin,
  selectUser,
  selectLoading,
  selectPayload,
  login,
} from '../../domain/userSlice';
import { useRef } from 'react';
import theme from '../../../app/config/themaConfig';

export default function SinginForm({ onLogin, setShowForgot }) {
  const passwordInputRef = useRef(null);
  const [hasToken, setHasToken] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const classes = useStyles();
  const borderRadius = clsx(classes.padding, classes.borderRadius);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const isLoading = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const payload = useSelector(selectPayload);
  const primaryColor = theme.palette.primary.main;

  useEffect(() => {
    if (user && user.usernameOrEmail) {
      setValue('cel', user.usernameOrEmail);
    }
  }, [user, setValue]);

  const onSubmit = async (data) => {
    // Validar reCAPTCHA antes de enviar la solicitud de inicio de sesión
    if (!hasToken) {
      alert('Completa el reCAPTCHA');
      return;
    }

    console.log('Form submitted with data:', data);
    dispatch(
      postLogin({
        usernameOrEmail: data.cel,
        password: data.password,
      })
    );
  };

  const inputStyles = {
    border: `1px solid ${primaryColor}`,
    color: 'black',
  };

  const inputStylespass = {
    border: `1px solid ${primaryColor}`,
    color: 'black',
    paddingRight: '40px',
  };

  return (
    <div className={classes.centerv}>
      <div className="containerlogin">
        <Paper className={borderRadius}>
          <div className={classes.margin}>
            <Grid
              container
              justify="center"
              style={{ marginTop: '15px' }}
            >
              <img
                src="https://centraldevida.s3.amazonaws.com/Logos/EVBI+-+Logo__3.png"
                width="300"
                alt="Logo"
              />
            </Grid>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyPress={(e) =>
                e.key === 'Enter' && handleSubmit(onSubmit)
              }
            >
              <Grid
                container
                spacing={8}
                alignItems="flex-end"
                style={{ marginTop: '15px' }}
              >
                <Grid
                  item
                  md={true}
                  sm={true}
                  xs={true}
                  style={{ padding: '32px 32px 32px 0px' }}
                >
                  <input
                    placeholder="Documento de identidad "
                    label="Celular"
                    fullWidth
                    name="cel"
                    type="tel"
                    {...register('cel', {
                      required: 'Documento de identidad Requerido.',
                    })}
                    error={!!errors.cel}
                    helperText={errors.cel?.message}
                    style={inputStyles}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={8}
                alignItems="flex-end"
              >
                <Grid
                  item
                  md={true}
                  sm={true}
                  xs={true}
                  style={{
                    padding: '32px 32px 32px 0px',
                    width: '10%',
                  }}
                >
                  <input
                    label="Código enviado por SMS"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    placeholder="Código..."
                    name="password"
                    ref={passwordInputRef}
                    {...register('password', {
                      required: 'Contraseña requerida.',
                    })}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    style={inputStylespass}
                    inputMode="numeric" // Establecer inputMode en "numeric"
                  />
                  <InputAdornment
                    position="end"
                    style={{
                      cursor: 'pointer',
                      marginBottom: '5px',
                    }}
                    onClick={() =>
                      setShowPassword(!showPassword)
                    }
                  >
                    {showPassword ? (
                      <VisibilityIcon
                        style={{ color: primaryColor }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{ color: primaryColor }}
                      />
                    )}
                  </InputAdornment>
                </Grid>
              </Grid>
              {/* Agregar el componente ReCAPTCHA */}
              <Grid container justify="center" style={{ marginTop: '15px' }}>
                <ReCAPTCHA
                  sitekey="6LfuDWApAAAAAJ9VCgTF9JhUTTIfSFqhx5So_P45"
                  onChange={(value) => setHasToken(true)}
                />
              </Grid>
              <Grid
                container
                justify="space-between"
                alignItems="flex-end"
                style={{ marginTop: '10px', marginLeft: '17px' }}
              >
                <Grid item>
                  <Button
                    disableFocusRipple
                    disableRipple
                    style={{ textTransform: 'none' }}
                    variant="text"
                    color="primary"
                    onClick={() => setShowForgot(true)}
                  >
                    ¿Has olvidado tu código?
                  </Button>
                </Grid>
              </Grid>
              <div className={classes.wrapper}>
                <Grid
                  container
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: 'none',
                      width: '40%',
                      height: '40px',
                      borderRadius: '10px',
                      color: 'white',
                      fontSize: '16px',
                      backgroundColor: primaryColor,
                      border: `1px solid ${primaryColor}`,
                    }}
                  >
                    Ingresar
                  </Button>
                </Grid>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
              {payload.error && (
                <Alert severity="error">
                  Usuario o Contraseña no son Validos
                </Alert>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
              >
                <b style={{ color: primaryColor }}>
                  {`${process.env.REACT_APP_VERSION}`}
                </b>
              </Typography>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}
