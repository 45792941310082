import React from 'react';
import List from './List';
import { Grid, Paper } from '@material-ui/core';
import { CentalDeVidaStyle } from '../../domain/CentalDeVidaStyle';

import clsx from 'clsx';

const ListMachine = (props) => {

    const classes = CentalDeVidaStyle();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    return (
        <>
            <Grid item xs={12}>
                <Paper  className={classes.paper} >
                    <List/>
                </Paper>
            </Grid>
        </>


    );
}

export default ListMachine;