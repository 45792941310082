import { makeStyles } from '@material-ui/core/styles';
import bacround from '../MedicionesUser/images/Background2.png'

export const ImcPortalStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: ' 0px 0px 0px 0px',
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: '0px 0px 0px 0px'


  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'

  },
  fixedHeight: {
    height: 290,
  },
  footer: {
    backgroundcolor: 'green',
    position: 'fixed',
    width: 100,
    bottom: 0,
    color: 'white',
    fontsize: 25
  },
  overflow: 'hidden',

}));