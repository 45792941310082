import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { FaQuestion, FaRegCalendarDays, FaStar } from "react-icons/fa6";
import { Grid } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import '../../Styles/stylesIMC.css';
import RangeSVG from './graficas/RangeSVG';
import Rectangle from './graficas/RadialBarChart';
import TrianglesRow from './graficas/Triangulos';
import CircularProgress from '@material-ui/core/CircularProgress';

const calcularTamañoHollow = (serieFormateada, DataRangos, rangovalue) => {
  const valorSerieDefinido = serieFormateada !== undefined ? serieFormateada : 0;

  if (Array.isArray(DataRangos)) {

    const rangoEncontrado = DataRangos.find((rango) => rango.valor === rangovalue);

    if (rangoEncontrado) {
      const color = obtenerColorPorLabel(rangoEncontrado.valor);
      const { valor, descripcion } = rangoEncontrado;

      // Calcular el hollowSize según el rango encontrado
      return {
        hollowSize: ((valorSerieDefinido * 80) / 45).toFixed(1),
        label: valor ? valor : 'no se encontró rango',
        descripcion: descripcion ? descripcion : '',
        color: color
      };
    } else {
      // Manejar el caso en el que no se encuentra el rango
      return {
        hollowSize: '0',
        label: 'No se encontró rango',
        descripcion: '',
        color: "#1983c4"
      };
    }
  } else {
    // Handle the case where DataRangos is not an array
    console.error('DataRangos is not an array!');
    return {
      hollowSize: '0',
      label: 'Error: No se encontró rango',
      descripcion: '',
      color: "#1983c4"
    };
  }
};
const obtenerColorPorLabel = (label) => {
  // console.log(label,"label")
  switch (label) {
    
    case "Bajo":
      return "#1983c4";
    case "Normal":
      return "#005aa9";
    case "Sobre Peso":
      return "#48457c";
    case "Obesidad Clase l":
      return "#79385f";
    case "Obesidad Clase ll":
      return "#a92a41";
    case "Obesidad Clase lll":
      return "#d91c23";
    default:
      return "#1983c4"; // Color por defecto
  }
};

const CardImc = ({ valorSerie, DataRangos, handleComponentChange, handleComponentRango, textStyle,rangovalue }) => {
  const serieFormateada=Math.floor(valorSerie * 10) / 10;
  const [showDescription, setShowDescription] = useState(false);
  const { hollowSize, label, descripcion, color } = calcularTamañoHollow(serieFormateada, DataRangos,rangovalue);
  const serie2 = 25.00
  const hollowserie2 = 44.44
  const handleButtonClick = (accion, key) => {
    handleComponentChange(accion);
    handleComponentRango(key)

  };

  const options = {
    chart: {
      height: 100,
      type: "radialBar",
    },
    series: [hollowSize, hollowserie2],
    colors: [color, "#005aa9"],
    plotOptions: {
      radialBar: {
        track: {
          background: "#D9D8D8",
          strokeWidth: "100%"
        },
        hollow: {
          margin: 0,
          size: "40%",
          background: "transparent",
        },
        dataLabels: {
          name: {
            offsetY: 15,
            show: true,
            fontSize: "11px",
            colors: color,
          },
          value: {
            fontWeight: "bold",
            offsetY: -15,
            fontSize: "25px",
            show: true,
            formatter: function (val) {
              if (val === hollowSize) {
                const valorFormateado = (Math.floor(serieFormateada * 10) / 10);
                if (!isNaN(valorFormateado)) {
                  return valorFormateado.toFixed(1);
                }

              }
              else {
                return serie2.toFixed(1);
              }

            },
            color: color
          },
          total: {
            show: true,
            label: label,
            color: color,
            fontSize: "11px",
            formatter: function (val) {
              const valorFormateado = (Math.floor(serieFormateada * 10) / 10);
              if (!isNaN(valorFormateado)) {
                return valorFormateado.toFixed(1);
              }
            }
          }
        },

      },

    },
    labels: [label, ""],
    stroke: {
      lineCap: "round",
      width: 1
    },
  };

  useEffect(() => {
    const chart = new ApexCharts(document.querySelector('#chart'), options);
    chart.render();
    if (valorSerie != null) {
      const timeoutId = setTimeout(() => {
        setShowDescription(true);
      }, 500);

      // Limpiar el temporizador cuando el componente se desmonta o cuando cambian sistolica o diastolica
      return () => clearTimeout(timeoutId);
    }

  }, [serieFormateada, hollowSize, label, DataRangos, color]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulando carga
    const timeoutId = setTimeout(() => {
      setLoading(false); 
    }, 6000);

    // Limpieza del timeout al desmontar el componente
    return () => clearTimeout(timeoutId);
  }, []); 
  
  return (
    <div className="card">
      <div className="grafica-radial">
      {loading ? (
            <CircularProgress style={{marginLeft:'45%',marginTop:'10px'}} />
            ) : (
        <div className="grafica-radial-container">
          <div className="grafica-radial">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <div>
                  <div className='contenedorcolores'>
                    <FiberManualRecordIcon style={{ color: "#005aa9" }} className='thecolors' />
                    <span style={{ verticalAlign: "center", marginTop: "2%", color: "#005aa9" }}>Normal</span>
                  </div>
                  <div className='contenedorcolores'>
                    <FiberManualRecordIcon style={{ color }} className='thecolors' />
                    <span style={{ verticalAlign: "top", color }}>Tu IMC</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ApexCharts options={options} series={options.series} type="radialBar" height={190} />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid className='tablagrafica'>
                <RangeSVG percentage={serieFormateada} />
                <Rectangle />
                <TrianglesRow />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid className='descrip'>
                <div >
                  {showDescription && (
                    <>
                      <div>
                        <div className='titledescription2' style={textStyle}>{label}</div>
                        <div className='descipcionimc'  style={{textAlign:'justify',color:textStyle.color}}>{descripcion}</div>
                      </div>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="botonesimc">
            <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
              <FaQuestion style={textStyle} />
              <p  style={textStyle}>Información</p>
            </button>
            <button className="botonescard" onClick={() => handleButtonClick('Container7', 'imc')}>
              <FaRegCalendarDays style={textStyle} />
              <p style={textStyle}>Historial</p>

            </button>
            <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
              <FaStar style={textStyle} />
              <p  style={textStyle}>Tips</p>
            </button>
          </div>
        </div>
         )}
      </div>
    </div>
  );
}

export default CardImc;