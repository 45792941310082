import React, { useMemo, forwardRef } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { Box, Button, ListItem, Tooltip } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WarningIcon from '@material-ui/icons/Warning';

import PostAddIcon from '@material-ui/icons/PostAdd';

import { Link as RouterLink } from 'react-router-dom';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
    return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
    createData(0, 'Diego Barrera', '117,5', '72', '26', 70),
    createData(1, 'Laura Garcia', '110', '65', '26', 80),
    createData(2, 'Camilo Barrera', '108', '65', '26', 75),
    createData(3, 'Diego Garcia', '90', '64', '26', 78),
    createData(4, 'Luciana Barrera', '112', '71', '26', 91),
    createData(5, 'Diego Barrera', '105', '73', '26', 79),
];

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        display: "flex",
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

export default function Orders() {

    let to = "/mediciones/imc"

    const renderLink = useMemo(
        () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    const classes = useStyles();
    return (
        <>
            <Box
                component="span"
                m={1}
                className={`${classes.spreadBox} ${classes.box}`}
            >
                <Title>Usuarios </Title>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                >
                    Exportar
                </Button>
            </Box>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Imc </TableCell>
                        <TableCell>Diastolica (mmHg)</TableCell>
                        <TableCell>Sistolica (mmHg)</TableCell>
                        <TableCell>Presion Alterial</TableCell>
                        <TableCell align="center">Frecuencia Cardiaca</TableCell>
                        <TableCell align="center">Advertencia</TableCell>
                        <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>{row.paymentMethod}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.shipTo}</TableCell>
                            <TableCell>{`${row.name} / ${row.shipTo}`}</TableCell>
                            <TableCell align="center">{row.amount}</TableCell>
                            <TableCell><WarningIcon color="primary" /></TableCell>

                            <TableCell align="center" className={classes.box} >


                                <ListItem button component={renderLink}>
                                    <PostAddIcon />
                                </ListItem>

                                {/* <ListItem button component={renderLink}>
                                    <PostAddIcon />
                                </ListItem>
                                <ListItem button component={renderLink}>
                                    <PostAddIcon />
                                </ListItem> */}
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault}>
                    See more orders
        </Link>
            </div>
        </>
    );
}