import React from 'react';
import './../../../Styles/medicionesUser.css';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { RiBodyScanFill } from "react-icons/ri";
import { GiBiceps, GiMuscleUp } from "react-icons/gi";
import { FaShieldHeart } from "react-icons/fa6";
import { BsDropletHalf } from "react-icons/bs";
import { IoFlash } from "react-icons/io5";
import { FaWeightHanging } from "react-icons/fa";

const Mediciones = ({ handleComponentChange, handleComponentRango, textStyle }) => {
  const handleButtonClick = (accion, key) => {
    handleComponentChange(accion);
    handleComponentRango(key)

  };

  return (
    <div className="container2">
      <div className="column">
        <div className="row1">
          <div className="box" onClick={() => handleButtonClick('Container4', 'presion')}>
            <p style={textStyle}>Presión Arterial</p>
            <FaShieldHeart className='iconos' style={textStyle} />

          </div>
          <div className="box" onClick={() => handleButtonClick('Container5', 'pgc')}>
            <p style={textStyle}>Porcentaje de Grasa Corporal</p>
            <RiBodyScanFill className='iconos' style={textStyle} />

          </div>
        </div>
        <div className="row" >
          <div className="box"onClick={() => handleButtonClick('Container6', 'oximetria')}>
            <p style={textStyle}>Oximetría</p>
            <BsDropletHalf className='iconos' style={textStyle} />

          </div>
          <div className="box"  onClick={() => handleButtonClick('Container12', 'pgc')}>
          <p style={textStyle}>Metabolismo Basal</p>
            <IoFlash className='iconos' style={textStyle}  />

          </div>
        </div>
        <div className="row">
          <div className="box"onClick={() => handleButtonClick('Container13', 'pgc')}>
          <p style={textStyle}>Masa muscular esqueletica</p>
            {/* <GiBiceps className='iconos'/> */}
            <GiMuscleUp className='iconos'style={textStyle} />


          </div>
          <div className="box" onClick={() => handleButtonClick('Container14', 'pgc')}>
          <p style={textStyle}>Nivel de grasa visceral </p>
            <FaWeightHanging  className='iconos' style={textStyle}  />

          </div>
        </div>
      </div>
    </div>
  );
};

export default Mediciones;