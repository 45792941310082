import React from 'react';

import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';

import clsx from 'clsx';

import Orders from './Orders';


import { PresionAlterialStyle } from './PresionAlterialStyle';


export const UserTable = (props) => {

    const classes = PresionAlterialStyle();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    return (
        <>

            {/* <Grid item xs={12} >
                <Paper className={fixedHeightPaper}>
                    <Deposits />
                </Paper>
            </Grid> */}
            {/* Recent Orders */}
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Orders />
                </Paper>
            </Grid>
        </>
    );
}
