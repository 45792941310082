import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, AreaChart } from 'recharts';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LabelList,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import Title from './Title';

// Generate Sales Data
function createData(time, amount) {
    return { time, amount };
}


const data = [
    {
        name: '20/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 117,
        sis: 72,
    },
    {
        name: '21/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 110,
        sis: 65,
    },
    {
        name: '22/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 108,
        sis: 65,
    },
    {
        name: '23/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 90,
        sis: 64,
    },
    {
        name: '24/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 112,
        sis: 71,
    },
    {
        name: '25/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 105,
        sis: 65,
    },
    {
        name: '26/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 102,
        sis: 73,
    },
    {
        name: '27/04',
        maxdia: 120,
        mindia: 100,
        maxsis: 80,
        minsis: 50,
        dia: 111,
        sis: 71,
    },
];


export default function Chart() {
    const theme = useTheme();

    return (
        <>
            <Title>Presion Alterial</Title>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={500}
                    height={800}
                    data={data}
                    margin={{
                        top: 10,
                        right: 10,
                        bottom: 0,
                        left: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    {/* <CartesianGrid stroke="#f5f5f5" /> */}
                    <XAxis dataKey="name" label={{ value: '', position: 'insideBottomRight', offset: 0 }} scale="band" />
                    <YAxis domain={['dataMin -10', 'dataMax + 10']} label={{ value: 'Presion Alterial', angle: -90, position: 'center' }} />
                    {/* <Tooltip /> */}
                    <Legend />
                    <Area type="monotone" dataKey="maxdia" fill="#fff" stroke="#ffc658" />
                    <Area type="monotone" dataKey="mindia" fill="#fff" stroke="#8884d8" />
                    <Area type="monotone" dataKey="maxsis" fill="#fff" stroke="#82ca9d" />
                    <Area type="monotone" dataKey="minsis" fill="#fff" stroke="#FD8244" />
                    <Line type="monotone" dataKey="dia" stroke="#000" strokeWidth={1.5} activeDot={{ r: 8 }}  >
                        <LabelList dataKey="dia" position="top" />
                    </Line>
                    <Line type="monotone" dataKey="sis" stroke="#900" strokeWidth={1.5} activeDot={{ r: 8 }}  >
                        <LabelList dataKey="sis" position="top" />
                    </Line>
                </ComposedChart>
            </ResponsiveContainer>
        </>
    );
}