import React, { useState } from 'react';

import { Style } from '../PollStyle';
import clsx from 'clsx';
import { Grid, Paper, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Title from '../../../orussystem/Conponent/Title';

export default function QuestionPublic({ quetion, size, index, handleCheck, type }) {

    const classes = Style();
    const fixedHeightPoll = clsx(classes.paper, classes.fixedHeight);

    const [value, setValue] = useState('female');
    const [answers, setAnswers] = useState(quetion.answers);
    const [response, setResponse] = useState("")

    const handleChange = (event, answ) => {
        if (event.target.id !== "response") {
            setValue(event.target.value);
            const result = answers.filter(an => an.answer == event.target.value);
            handleCheck(...result, quetion.id)
        } else {
            
            const res = {
                answer: answ[0].answer,
                detalle: event.target.value,
                state: "A",
                id: answ[0].id,
                createDate: answ[0].createDate,
                updateDate: answ[0].updateDate
            }

            setResponse(event.target.value)
            handleCheck(res, quetion.id)
        }

    };

    return (
        <>
            <Grid item xs={12}>
                <Paper className={fixedHeightPoll}>
                    <Grid item xs={12} sm={6} >
                        <Title component="h1">Pregunta {index + 1}/{size}</Title>
                        <h3>{quetion.question}</h3>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        {type === "C" && <FormControl component="fieldset">
                            <FormLabel component="legend">Respuestas</FormLabel>
                            <RadioGroup aria-label={index} name={index} value={value} onChange={(e)=>handleChange(e, answers)}>
                                {
                                    answers.map(a => {
                                        return <FormControlLabel key={a.id} value={a.answer} control={<Radio />} label={a.answer} />
                                    })
                                }
                            </RadioGroup>
                        </FormControl>}
                        {type !== "C" &&
                            <TextField
                                id="response"
                                label="Respuesta"
                                fullWidth
                                multiline
                                value={response}
                                onChange={(e)=>handleChange(e, answers)}

                            />
                        }
                    </Grid>
                </Paper>
            </Grid>

        </>


    );
}