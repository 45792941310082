import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { FaQuestion, FaRegCalendarDays, FaStar } from "react-icons/fa6";
import '../../Styles/stylesIMC.css'
import RangeigcSVG from './graficas/RangeigcSVG';
import TablaigcSVG from './graficas/TablaigcSVG';
import TrianglesigcRow from './graficas/TriangulosigcSVG';
import { colors } from '@material-ui/core';

const calcularTamañoHollow = (DataRangos, genero, Igc) => {
  const generoKey = genero === 'M' ? 'hombre' : 'mujer';

  for (const rango of DataRangos) {
    const parametros = JSON.parse(rango.parametros);

    // Verifica si el rango es para el género actual
    if (parametros && parametros[generoKey]) {
      const [min, max] = parametros[generoKey].split('-').map(Number);

      // Comprueba si Igc está dentro del rango
      if (Igc >= min && Igc <= max) {
        const label = rango.valor;
        const descripcion = rango.descripcion;

        return {
          label,
          descripcion,
        };
      }
    }
  }


  return {
    label: '',
    descripcion: '',
  };
};
const CardIgc = ({ Igc, genero, DataRangos, handleComponentChange, handleComponentRango, textStyle }) => {

  const [showDescription, setShowDescription] = useState(false);
  const [Rango, setRango] = useState('');
  const [ColorRango, setColorRango] = useState('');
  const generoKey = genero === 'M' ? 'hombre' : 'mujer';
  const [Descripcion, setDescripcion] = useState('');
  const igcParts = (Igc ?? '0').toString().split('.');
  const handleButtonClick = (accion, key) => {
    handleComponentChange(accion);
    handleComponentRango(key)

  };

  let igcString = igcParts[0]; // Parte entera

  if (igcParts[1] !== undefined) {
    igcString += '.' + igcParts[1].charAt(0); // Parte decimal con un solo dígito
  }
  useEffect(() => {
    if (DataRangos && DataRangos.length > 0) {
      const { label, descripcion } = calcularTamañoHollow(DataRangos, genero, Igc);
      setRango(label);
      setDescripcion(descripcion);
    } else {
      setRango('No hay rangos disponibles');
      setDescripcion('');
    }
    if (Igc != null) {
      const timeoutId = setTimeout(() => {
        setShowDescription(true);
      }, 500);

      // Limpiar el temporizador cuando el componente se desmonta o cuando cambian sistolica o diastolica
      return () => clearTimeout(timeoutId);
    }
  }, [Igc, DataRangos, genero, ColorRango]);

  const options = {
    chart: {
      height: 250,
      type: 'radialBar',
    },
    series: [((Igc / 45) * 100).toFixed(1)],
    colors: [ColorRango],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#F2F2F2',
          startAngle: -90,
          endAngle: 90,
        },
        dataLabels: {
          showOn: 'always',
          name: {
            offsetY: -35,
            show: true,
            color: ColorRango,
            fontSize: '18px',
          },
          value: {
            fontWeight: "bold",
            color: ColorRango,
            offsetY: -20,

            fontSize: '32px',
            show: true,
            formatter: function (val) {

              return igcString + '%';
            }
          },
        },
      },
    },

    stroke: {
      lineCap: 'butt',
      dashArray: 4,
    },
    labels: [Rango],

  };


  return (

    <div className="card">
      <div className="card-content">
        <div>

          <div className="grafica-radial-igc">
            <ApexCharts options={options} series={options.series} type="radialBar" height={250} />
            <div >

              <RangeigcSVG percentage={Igc} setColorRango={setColorRango} Rango={Rango} />
              <TablaigcSVG DataRangos={DataRangos} genero={generoKey} />
              <TrianglesigcRow />

            </div>
            {showDescription && (
              <div>
                <div className='titledescription' style={textStyle}>{Rango}</div>
                <div className='descripcion' style={{ textAlign: 'justify', color: textStyle.color }}>{Descripcion}</div>
              </div>
            )}
          </div>


          <div className="botonesigc">
            <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
              <FaQuestion style={textStyle} />
              <p style={textStyle}>Información</p>
            </button>
            <button className="botonescard" onClick={() => handleButtonClick('Container9', 'pgc')} style={textStyle}>
              <FaRegCalendarDays style={textStyle} />
              <p style={textStyle}>Historial</p>

            </button>
            <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
              <FaStar style={textStyle} />
              <p style={textStyle}>Tips</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardIgc;
