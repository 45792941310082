import React from "react";

const TrianglesRow = () => {
  const colors = [
    { color: "#1983c4", label: "Bajo" },
    { color: "#005aa9", label: "Normal" },
    { color: "#48457c", label: "SobrePeso" },
    { color: "#79385f", label: "Obesidad,\nclase l" },
    { color: "#a92a41", label: "Obesidad,\nclase ll" },
    { color: "#d91c23", label: "Obesidad,\nclase lll" },
  ];

  const triangleSize = 7;
  const triangleHeight = (Math.sqrt(3) / 2) * triangleSize; // Altura de los triangulos

  return (
    <svg width="100%" height={triangleHeight + 40} xmlns="http://www.w3.org/2000/svg">
      {colors.map((item, index) => (
        <g key={index}>
          <polygon
            points={`${index * 49.5 + 10 + index * 5 + 9 + (index < 3 ? 5 : 0)} ,${0} ${(index * 49.5 + 10 + index * 5 + 9 + (index < 3 ? 5 : 0)) - triangleSize / 2},${triangleHeight + 0} ${(index * 49.5 + 10 + index * 5 + 9 + (index < 3 ? 5 : 0)) + triangleSize / 2},${triangleHeight + 0}`}
            fill={item.color}
            letterSpacing="2"
          />
          <text
            x={`${index * 49.5 + 10 + index * 5 + 9 + (index < 3 ? 5 : 0)}`}
            y={triangleHeight - 1}
            textAnchor="middle"
            dominantBaseline="middle"
            fill={item.color}
            fontSize="10"
            fontWeight="bold"
            style={{
              maxWidth: "60px",
              whiteSpace: "pre-line",
            }}
          >
            {item.label.split("\n").map((line, i) => (
              <tspan key={i} x={`${index * 49.5 + 10 + index * 5 + 9 + (index < 3 ? 5 : 0)}`} dy="1em">
                {line}
              </tspan>
            ))}
          </text>
        </g>
      ))}
    </svg>
  );
};


export default TrianglesRow;
