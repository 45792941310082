import React, { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineChartPeso = ({ data, DataRangos, altura, genero }) => {
    // Extrae los valores de peso y las fechas correspondientes desde los últimos 10 registros de datos
    const lastTenData = data.slice(-5).reverse();  // Invertir el orden de los datos
    const pesoValues = lastTenData.map((record) => record.peso );
    const [min, setmin] = useState("");
    const [max, setmax] = useState("");

    // Meses correspondientes a los últimos 10 puntos en el eje X
    const months = lastTenData.map((record) => {
        const date = new Date(record.datecreate);
        const day = date.getDate();
        const monthNames = [
            "ene", "feb", "mar", "abr", "may", "jun",
            "jul", "ago", "sep", "oct", "nov", "dic"
        ];
        const month = monthNames[date.getMonth()];
        return `${day} ${month}`;
    });

    // Función para calcular el rango óptimo de peso según  altura
    const calcularRangoOptimo = (altura) => {
        const Altura=altura===undefined?1.50:altura
           setmin(18.5*(Altura*Altura)) ;
           setmax(25*(Altura*Altura)) ;

    };


   
    useEffect(() => {
        calcularRangoOptimo(altura)
    
      }, [altura]);
    
    // Configuración de la gráfica
    const options = {
        chart: {
            id: "line-chart",
            width: "80%",
        },
        xaxis: {
            categories: months, // Usamos los meses como categorías
        },
        yaxis: {
            min: 40, // Establece el valor mínimo en el eje Y
            max: 100, // Establece el valor máximo en el eje Y
            tickAmount: 3, // Establece la cantidad de valores en el eje Y
            labels: {
                colors: ["#005aa9"], // Establece el color de los valores en el eje Y
                formatter: function (value) {
                    return Math.round(value); // Redondea los valores a números enteros
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 0.2,
                opacityFrom: 0.7,
                opacityTo: 0.7,
                stops: [0, 100],
            },
        },
        stroke: {
            width: 3,
            colors: ["#a92a41"], // Color de la línea aquí
            opacity: 3,
        },
        markers: {
            size: 4,
            colors: '#a92a41', // Color de los puntos (en este caso, el color de peso)
        },
        annotations: {
            yaxis: [
                {
                    y: min,
                    y2: max,
                    fillColor: "#a92a41", // Color del sombreado del rango óptimo
                    opacity: 0.2, // Opacidad del sombreado del rango óptimo
                },
            ],
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const month = months[dataPointIndex]; // Mes correspondiente al punto
                const pesoValue =  Math.floor(pesoValues[dataPointIndex] * 10) / 10;// Valor de peso

                // Encuentra el rango al que pertenece el valor de peso actual


                return (
                    '<div class="custom-tooltip"style="height:50px; width:120px;font-size:12px;border-radius:10%;">' +
                    '<div class="tooltip-title" style="background-color:#005aa9; color: white; padding-left: 10px; opacity:1">' +
                    month + // Mes en el título
                    '</div>' +
                    '<div class="tooltip-content" style="height: 60px;padding-top: 5px; ">' + // Aumentamos la altura
                    '<div class="sales-circle" style="background-color: ' +
                    w.globals.stroke.colors[seriesIndex] + // Color de la línea (círculo)
                    '; border-radius: 50%; width: 10px; height: 10px; display: inline-block; margin-right: 5px;margin-left: 10px; "></div>' +
                    '<span class="sales-text" style="font-weight: bold; margin: 0;">' + // Eliminamos margen para IMC
                    'Peso: ' + pesoValue +
                    '</span>' + // Estilos de "Ventas"
                    '</div>' +
                    '</div>'
                );
            },
        },
    };

    return (
        <div>
            <div>
                <ReactApexChart options={options} series={[{ name: "Peso", data: pesoValues }]} type="line" height={170} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "-5%", marginLeft: "-30%", marginBottom: "6%" }}>
                <div style={{ marginRight: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                width: "10px",
                                height: "3px",
                                backgroundColor: "#a92a41",
                                marginRight: "5px",

                            }}
                        ></div>
                        Peso
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#a92a41",
                                opacity: 0.2,
                                marginRight: "5px",
                            }}
                        ></div>
                        Rango Óptimo
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LineChartPeso;
