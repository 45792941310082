import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ data, DataRangos }) => {
    // Tomar solo los primeros 5 registros
    const last5Data = data.slice(-5).reverse();
    const imcValues = last5Data.map((record) => record.imc);
    // Meses correspondientes a los puntos en el eje X
    const months = last5Data.map((record) => {
        const date = new Date(record.datecreate);
        const day = date.getDate();
        const monthNames = [
            "ene", "feb", "mar", "abr", "may", "jun",
            "jul", "ago", "sep", "oct", "nov", "dic"
        ];
        const month = monthNames[date.getMonth()];
        return `${day} ${month}`;
    });
    // Configuración de la gráfica
    const options = {
        chart: {
            id: "line-chart",
            width: "80%",
        },
        xaxis: {
            categories: months, // Usamos los meses como categorías
        },
        yaxis: {
            min: 10, // Establece el valor mínimo en el eje Y
            max: 55, // Establece el valor máximo en el eje Y
            tickAmount: 3, // Establece la cantidad de valores en el eje Y
            labels: {
                colors: ["#005aa9"], // Establece el color de los valores en el eje Y
                formatter: function (value) {
                    return Math.round(value);
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 0.2,
                opacityFrom: 0.7,
                opacityTo: 0.7,
                stops: [0, 100],
            },
        },
        stroke: {
            width: 3,
            colors: ["#005aa9"], // Color de la línea aquí
            opacity: 3,
        },
        markers: {
            size: 4,
            colors: '#005aa9', // Color de los puntos (en este caso, el color de peso)
        },
        annotations: {
            yaxis: [
                {
                    y: 18.5,
                    y2: 25,
                    fillColor: "#005aa9", // Color del sombreado del rango óptimo
                    opacity: 0.2, // Opacidad del sombreado del rango óptimo
                },
            ],
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const month = months[dataPointIndex]; // Mes correspondiente al punto
                const salesValue = Math.floor(series[0][dataPointIndex] * 10) / 10; // Valor de ventas

                // Encuentra el rango al que pertenece el valor de IMC actual
                let rangoDescripcion = "Desconocido";
                for (const rango of DataRangos) {
                    const parametros = JSON.parse(rango.parametros);
                    const [min, max] = parametros.imc.split("-").map(parseFloat);
                    if (salesValue > min && salesValue <= max) {
                        rangoDescripcion = rango.valor;
                        break;
                    }
                }

                return (
                    '<div class="custom-tooltip"style="height:68px; width:120px;font-size:12px;border-radius:10%;">' +
                    '<div class="tooltip-title" style="background-color: ' +
                    w.globals.stroke.colors[seriesIndex] +
                    '; color: white; padding-left: 10px; opacity:1">' +
                    month + // Mes en el título
                    '</div>' +
                    '<div class="tooltip-content" style="height: 60px;padding-top: 5px; ">' +
                    '<div class="sales-circle" style="background-color: ' +
                    w.globals.stroke.colors[seriesIndex] +
                    '; border-radius: 50%; width: 10px; height: 10px; display: inline-block; margin-right: 5px;margin-left: 10px; "></div>' +
                    '<span class="sales-text" style="font-weight: bold; margin: 0;">' +
                    'IMC: ' + salesValue +
                    '</span>' +
                    '<div class="rango-description" style="background-color: ' +
                    w.globals.stroke.colors[seriesIndex] +
                    '; color: white; padding-left: 10px; font-weight: bold;margin-top: 10px;">' + rangoDescripcion +
                    '</div>' +
                    '</div>' +
                    '</div>'
                );
            },
        },
    };

    return (
        <div>
            <div>
                <ReactApexChart options={options} series={[{ name: "IMC", data: imcValues }]} type="line" height={170} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "-5%", marginLeft: "-30%", marginBottom: "6%" }}>
                <div style={{ marginRight: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                width: "10px",
                                height: "3px",
                                backgroundColor: "#005aa9",
                                marginRight: "5px",
                            }}
                        ></div>
                        IMC
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#005aa9",
                                opacity: 0.2,
                                marginRight: "5px",
                            }}
                        ></div>
                        Rango Óptimo
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LineChart;
