import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdCalendarMonth } from 'react-icons/md';
import './../../../Styles/Perfil.css';

const CustomDatePicker = ({ selected, onChange, textStyle }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };
  const datePickerStyles = {
    // Otros estilos que desees aplicar
    backgroundColor: textStyle.color, // Utiliza el color de fondo de textStyle
    border: `1px solid ${textStyle.color}`
  };
  const inputStyles = {
    border: `1px solid ${textStyle.color}`, // Establece el borde sólido con el color proporcionado
    borderRadius: '10px',
    height: '38px',
    paddingLeft: '8%',
    marginLeft: '10%',
    width: '98%'
  };


  return (
    <div className="date-picker-container" onClick={toggleCalendar} type="fecha">
      <div className="date-picker-icon" >
        <MdCalendarMonth style={textStyle} />
      </div>
      <DatePicker
        type='fecha'
        selected={selected}
        onChange={onChange}
        dateFormat="yyyy/MM/dd"
        showYearDropdown
        scrollableYearDropdown
        open={calendarOpen}
        style={datePickerStyles}
        dayClassName={(date) => {
          if (date.getDate() === selected.getDate()) {
            return 'custom-selected-day';
          }
          return undefined;
        }}
        // Aplica los estilos al input
        customInput={<input style={inputStyles} />}


      />
    </div>
  );
};

export default CustomDatePicker;
