import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, AreaChart } from 'recharts';
import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    LabelList,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import Title from '../../../Title';
import { UtilsDate } from '../../../../../orussystem/utils/UtilDate';
import { Bar } from 'recharts';
import { BarChart } from 'recharts';

import { CartesianGrid } from 'recharts';
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(15),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
// Generate Sales Data
function createData(time, amount) {
    return { time, amount };
}


export default function ChartOximetria({ oximetria }) {

    const theme = useTheme();
    const history = useHistory()

    const dataTemp = oximetria.map(item => {
        return ({
            name: UtilsDate().formatDDMM(new Date(item.datecreated)),
            normal: item.oximetria.toFixed(0) >= 95 ? item.oximetria.toFixed(0) : 0,
            hipoxia: item.oximetria.toFixed(0) < 95 && item.oximetria.toFixed(0) > 90 ? item.oximetria.toFixed(0) : 0,
            hipoxia_grave: item.oximetria.toFixed(0) <= 90 ? item.oximetria.toFixed(0) : 0,
        })
    })

    const goBack = () => {
        history.push("/")
    }


    return (
        <>
            <Title>Oximetria</Title>
         
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            layout="vertical"
                            width={500}
                            height={300}
                            data={dataTemp}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                        >
                            <XAxis type="number" unit='%'/>
                            <YAxis dataKey="name" type="category" scale="band" />
                            <Bar dataKey="normal" stackId="a" fill="#2bb673" />
                            <Bar dataKey="hipoxia" stackId="a" fill="#fcd704" />
                            <Bar dataKey="hipoxia_grave" stackId="a" fill="#ec1f26" />
                        </ComposedChart>
                    </ResponsiveContainer>

            <Grid container spacing={2} style={{ padding: 0, marginTop: '10px', marginLeft: '1px', }}>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: "#2bb673" }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Normal</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Normal: "}</b>{' '}
                                            {"Mediciones de oximetria mayores a 95%."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: "#fcd704" }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Hipoxia Leve</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Hipoxia Leve: "}</b>{' '}
                                            {"Mediciones de oximetria entre 95% y 91%."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color: "#ec1f26" }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Hipoxia Moderada</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Hipoxia Moderada: "}</b>{' '}
                                            {"Mediciones de oximetria menores a 90%."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none", width: "inherit", marginTop: 20 }}
                onClick={goBack}
            >
                Volver
            </Button>
        </>
    );
}

