// App.js
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './app/config/themaConfig';
import { Dashboard } from './dashboard';
import { useSelector } from 'react-redux';
import { selectUser } from './user/domain/userSlice';
import { SinginForm, UserAdmin } from './user';
import { CentralDeVida } from './centraldevida';
import { menus as menuBascula } from './centraldevida/menu/menuJson';
import withAuthentication from './user/componet/session/withAuthentication';
import ForgotPasswordForm from './user/componet/Singin/ForgotPasswordForm';
import Recuperar from '../src/user/componet/Singin/Recuperar';
import backgrounddev from '../src/img/Background_dev.png';
import backgroundqa from '../src/img/Background_qa.png';
import backgroundprod from '../src//centraldevida/MedicionesUser/images/Background_log_08.png';
import 'react-toastify/dist/ReactToastify.css';
import { subscription } from './notificaciones';





function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showRecuperar, setShowRecuperar] = useState(false);

  const handleLogin = () => {
    setTimeout(() => {
      setIsLoggedIn(true);
    }, 1000);
  };

  const user = useSelector(selectUser);
  const menu = menuBascula();

  // Cambio de fondo dependiendo del entorno
  let backgroundImage;

  if (process.env.REACT_APP_BACKGROUND_IMAGE === 'background_dev') {
    backgroundImage = `url(${backgrounddev})`;
  } else if (process.env.REACT_APP_BACKGROUND_IMAGE === 'background_qa') {
    backgroundImage = `url(${backgroundqa})`;
  } else if (process.env.REACT_APP_BACKGROUND_IMAGE === 'background_prod') {
    backgroundImage = `url(${backgroundprod})`;
  } else {
    backgroundImage = 'var(--background-image)';
  }
 
  // const requestNotificationPermission = async () => {
  //   try {
  //     // Verifica si el navegador soporta las notificaciones
  //     if ('Notification' in window) {
  //       // Pregunta al usuario si quiere recibir notificaciones
  //       const permissionStatus = await Notification.requestPermission();
  //       // console.log('Permission status:', permissionStatus);

  //       if (permissionStatus === 'granted') {
  //         // Si el usuario concede el permiso, procede con la suscripción
  //         subscription();
  //       } else {
  //         console.log('El usuario no ha permitido las notificaciones.');
  //       }
  //     } else {
  //       console.log('El navegador no soporta notificaciones.');
  //     }
  //   } catch (error) {
  //     console.error('Error solicitando permiso de notificación:', error);
  //   }
  // };


  // useEffect(() => {
  //   // Llama a la función para solicitar el permiso de notificación al cargar el componente
  //   requestNotificationPermission();
  // }, []);


  return (
    <div className={user ? 'containerapp' : 'container'} style={{ backgroundImage: user ? '' : backgroundImage }}>
      <div className={user ? 'inner-container2' : 'inner-container'}>
        <ThemeProvider theme={theme}>
          {user ? (

            <Dashboard menu={menu}>
              <UserAdmin />

              <CentralDeVida menu={menu} />
            </Dashboard>
          ) : (
            (showForgot || showRecuperar) ? (
              showForgot ? (
                <ForgotPasswordForm setShowForgot={setShowForgot} showRecuperar={showRecuperar} setShowRecuperar={setShowRecuperar} />
              ) : (
                showRecuperar ? (
                  <Recuperar setShowRecuperar={setShowRecuperar} setShowForgot={setShowForgot} />
                ) : (
                  <SinginForm setShowForgot={setShowForgot} />
                )
              )
            ) : (
              <SinginForm setShowForgot={setShowForgot} />
            )
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}

export default withAuthentication(App);
