import React, { Children } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { DashboardStyle } from './DashboardStyle';
import { styled } from '@mui/material';
import Header from '../AppBarOrs/Header';
import { Copyright } from '../UI/CopyRight';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
import withAuthentication from '../../../user/componet/session/withAuthentication';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Footer from '../../../centraldevida/component/footer/footer';

const FullscreenContainer = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
`;
const ResponsiveContainer = styled('div')`
max-width: 1200px; /* Ajusta este valor según tu diseño */
width: 100%;
height: 100%; /* Ocupa todo el espacio vertical disponible */
padding: 0 0px; /* Ajusta el espaciado lateral según tu diseño */
display: flex;
flex-direction: column;
`;
function Dashboard({ children, menu }) {

  const classes = DashboardStyle();

  return (
    <Router>

      <FullscreenContainer>
        <ReactNotifications />
        <CssBaseline />
        {/* <Header title={process.env.REACT_APP_TITLE} _menus={menu} /> */}
      
          {/* <div className={classes.appBarSpacer} /> */}
          {/* <Container >
            <Grid >
              <Switch > */}
                <ResponsiveContainer>
                  {children}
                </ResponsiveContainer>
              {/* </Switch>
            </Grid>
          </Container> */}
    
     
      </FullscreenContainer>
    </Router>
  );
}

export default withAuthentication(Dashboard);
// export default Dashboard;
