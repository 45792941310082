import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESS_TOKEN } from "./constants";

const request = (options, state) => {
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    // if (localStorage.getItem(ACCESS_TOKEN)) {
    // 	headers.append(
    // 		"Authorization",
    // 		"Bearer " + localStorage.getItem(ACCESS_TOKEN)
    // 	);
    // }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === 401) {
                return {
                    ...json,
                    error: true,
                    process: 'failed',
                };
            }

            return { error: false, ...json };
        });
};

export const postLogin = createAsyncThunk(
    'user/login',
    async (login) => {

        return await request({
            url: `${process.env.REACT_APP_API_URL}/api/auth/signin`,
            method: "POST",
            body: JSON.stringify(login),
        });


    }
)
export function changeOpt (dispatch, Request){

	let url = `${process.env.REACT_APP_API_URL}/api/v1/user/changeOPT`;
	return request({
		url: url,
		method: "POST",
		body: JSON.stringify(Request),
	}, dispatch);
}


const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        process: null,
        isLoading: false,
        payload: { error: false }
    },
    reducers: {
        loginBytoken: (state) => {
            let token =localStorage.getItem(ACCESS_TOKEN)
            state.user = {accessToken:token}
        },
        logout: (state) => {
            state.user = null;
            localStorage.removeItem(ACCESS_TOKEN);
        },
        login: (state, data) => {
            state.user = {user:data };
            localStorage.setItem(ACCESS_TOKEN, data);
        },
    },
    extraReducers: {
        [postLogin.pending]: (state, action) => {
            state.process = 'loading'
            state.isLoading = true
            state.payload = { error: false }
        },
        [postLogin.fulfilled]: (state, { payload }) => {
            state.payload = payload
            state.user = !payload.error ? payload : null
            state.process = 'success'
            state.isLoading = false

            state.user && localStorage.setItem(ACCESS_TOKEN, payload.accessToken);
        },
        [postLogin.rejected]: (state, action) => {
            state.process = 'failed'
            state.isLoading = false
            state.payload = { error: true }

        },
    },

});

export const {  logout, loginBytoken, login  } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export const selectLoginApiStatus = (state) => state.user.process

export const selectPayload = (state) => state.user.payload

export const selectLoading = (state) => state.user.isLoading

export default userSlice.reducer;