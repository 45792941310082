import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './footerStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    borderRadius: '60px',
  },
  buttoninactive: {
    color: '#E9E9EC',
  },
  buttonactive: {
    color: 'primary',
  },
}));

export default function TusDatosIMC({ imc, peso, altura, key, textStyle }) {
  // Función para formatear el valor de imc
  const formatIMC = (imc) => {
    if (!isNaN(imc)) {
      // Redondear a un dígito después del punto decimal sin aproximación
      return Math.floor(imc * 10) / 10;
    } else {
      // Si no es un número, devolver un guion "-"
      return '-';
    }
  };

  return (
    <div className={key === 'imc' ? 'button-group' : 'button-group2'}>
      <button className="button1">
        <div className="number" style={textStyle}>
          <b>{altura ? (altura === 'NaN' ? '-' : altura) : 0}</b>
        </div>
        <div className="text" style={textStyle}>
          Altura(m)
        </div>
      </button>
      <button className="button">
        <div className="number" style={textStyle}>
          <b>{peso ? (peso === 'NaN' ? '-' : peso) : 0}</b>
        </div>
        <div className="text" style={textStyle}>
          Peso(Kg)
        </div>
      </button>
      <button className="button3">
        <div className="number" style={textStyle}>
          <b>{formatIMC(imc)}</b>
        </div>
        <div className="text" style={textStyle}>
          IMC
        </div>
      </button>
    </div>
  );
}
