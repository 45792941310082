import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { Box, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
    return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
    createData(0, '20 Abril, 2021', '117,5', '72', '26', 75),
    createData(1, '21 Abril, 2021', '110', '65', '26', 80),
    createData(2, '22 Abril, 2021', '108', '65', '26', 81),
    createData(3, '23 Abril, 2021', '90', '64', '26', 79),
    createData(4, '24 Abril, 2021', '112', '71', '26', 90),
    createData(4, '25 Abril, 2021', '105', '73', '26', 91),
];

function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        display: "flex",
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

export default function Orders() {
    const classes = useStyles();
    return (
        <>
            <Box
                component="span"
                m={1}
                className={`${classes.spreadBox} ${classes.box}`}
            >
                <Title>Datos de Reportados </Title>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                >
                    Exportar
                </Button>
            </Box>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Diastolica (mmHg)</TableCell>
                        <TableCell>Sistolica (mmHg)</TableCell>
                        <TableCell>Presion Alterial</TableCell>
                        <TableCell align="center">Frecuencia Cardiaca</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.shipTo}</TableCell>
                            <TableCell>{ `${row.name} / ${row.shipTo}` }</TableCell>
                            <TableCell align="center">{row.amount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault}>
                    See more orders
        </Link>
            </div>
        </>
    );
}