import React, { useEffect, useState } from 'react';
import './../../../Styles/Perfil.css';
import hombre from '../images/hombre.png';
import mujer from '../images/mujer.png';
import Avatar from '@mui/material/Avatar';
import DatosUsuarioPerfil from '../../component/footer/datosUsuarioPerfil';
import { EditarUsuario, getHistorico } from '../../domain/api-client/UserMachine';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import '../../../../node_modules/react-datepicker/dist/react-datepicker.module.css'
import CustomDatePicker from './CustomDatePicker';
import { useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 10,

    },
    titlemodal: {
        color: theme.palette.primary.main,
        textAlign: "center",
        margin:'5%',
        fontSize:'22px',
        fontWeight:'bold'
    },
    dialogActions: {
        justifyContent: 'center',
    },
}));
const EditUser = ({ reload, setReload, data, edad, nombre, genero, email, DataRangos, usuario, handleComponentRango, handleComponentChange, textStyle }) => {
    const classes = useStyles();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [Data, setData] = useState({});
    const fechaNacimientoInicial = new Date(data.edad);
    const [fechaNacimiento, setFechaNacimiento] = useState(fechaNacimientoInicial);
    const [altura, setAltura] = useState(data.altura);
    const [selectedGenero, setSelectedGenero] = useState(data.genero || "");
    const [inputEmail, setInputEmail] = useState(data.email || "");
    const [openModal, setOpenModal] = useState(false);

    const [alturaCentimetros, setAlturaCentimetros] = useState((altura * 100).toFixed(2));

  
    if (alturaCentimetros >= 1000) {
      setAlturaCentimetros(alturaCentimetros.substring(0, 3));
    }
    
    const [alturaInput, setAlturaInput] = useState(alturaCentimetros / 100);
    const [Nombre, setNombre] = useState(nombre);

    const dispatch = useDispatch();

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleAlturaChange = (event) => {
        const inputValue = event.target.value;

        // Limitar la entrada a 3 dígitos y solo números
        if (/^\d{0,3}$/.test(inputValue)) {
            setAlturaCentimetros(inputValue);

            // Convertir a metros y guardar en una variable
            const alturaEnMetros = parseFloat(inputValue) / 100;
            setAlturaInput(alturaEnMetros);
        }
    };

    useEffect(async () => {
        setAlturaCentimetros((altura * 100).toFixed());
        try {
            const result = await getHistorico(usuario);
            setData(result.content);
            setDataLoaded(!reload);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [data, edad, nombre, email, DataRangos, data.altura]);

    const avatarSrc = genero === 'F' ? mujer : hombre;
    const handleButtonClick = (accion, key) => {
        handleComponentChange(accion);
        handleComponentRango(key);
    };
    const handleeditar = async () => {
        try {
            if (!Nombre || !selectedGenero || !fechaNacimiento || !alturaInput) {
                toast.error('Por favor complete todos los campos.');
                return;
            }
            const alturaMetros = alturaCentimetros / 100;
            const requestBody = {
                documentId: usuario,
                name: Nombre,
                gender: selectedGenero,
                birth: fechaNacimiento.toISOString().split('T')[0],
                height: alturaInput ? alturaInput : alturaMetros,
            };
            const Editar = await EditarUsuario(dispatch, requestBody);
            setReload(true)
            toast.success('Tus datos han sido actualizados correctamente.');

            setTimeout(() => {
                handleButtonClick('Container3', 'imc');
            }, 2000);

        } catch (error) {
            console.error('Error al obtener datos: ', error);
            toast.error('Error al editar el usuario.');
        }
    };
    const showToast = () => {
        toast.success('Hello, world!');
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div className="perfil-container">
            <DatosUsuarioPerfil />
            <div className='scroll-containerEdit'>
                <div className="header">
                    <Avatar alt="Remy Sharp" src={avatarSrc} sx={{ width: 56, height: 56 }} />
                    <div className="profile-info">
                        <h2 className='nombreperfil' style={textStyle}>{capitalizeFirstLetter(nombre) || "-"}</h2>
                    </div>
                </div>
                <div className='editData'>
                    <div className="editData-text" style={{ marginLeft: "5%", color: textStyle.color }}>
                        Nombre:
                    </div>
                    <div className="editData-input">
                        <input
                            type="email"
                            value={Nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            style={{ marginLeft: "0%", border: `1px solid ${textStyle.color}`, width: '90%' }}
                        />
                    </div>
                </div>
                <div className='editData'>
                    <div className="editData-text" style={{ marginLeft: "5%", marginRight: "1%", maxWidth: "72px", color: textStyle.color }}>
                        Fecha de nacimiento:
                    </div>
                    <Grid item md={true} sm={true} xs={true} style={{ padding: '32px 32px 32px 0px', marginLeft: '8%' }}>
                        <CustomDatePicker
                            selected={fechaNacimiento}
                            onChange={(date) => setFechaNacimiento(date)}
                            textStyle={textStyle}
                            inputType="date"
                        />
                    </Grid>
                </div>
                <div className='editData'>
                    <div className="editData-text" style={{ marginLeft: "5%", color: textStyle.color }}>
                        Altura (cm):
                    </div>
                    <div className="editData-input">
                        <input
                            type="perfil" 
                            value={alturaCentimetros}
                            onChange={handleAlturaChange}
                            style={{ width: "87%", marginLeft: "0%", border: `1px solid ${textStyle.color}` }}
                        />
                    </div>
                </div>
                <div className='editData'>
                    <div className="editData-text" style={{ marginLeft: "5%", color: textStyle.color }}>
                        Género:
                    </div>
                    <div className="editData-input">
                        <select
                            value={selectedGenero === "F" ? "Femenino" : "Masculino"}
                            onChange={(e) => setSelectedGenero(e.target.value)}
                            style={{ marginLeft: "5%", width: "83%", border: `1px solid ${textStyle.color}`, height: "37px", borderRadius: "10px" }}
                        >
                            <option value={selectedGenero}>{selectedGenero === "F" ? "Femenino" : "Masculino"}</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                    </div>
                </div>

                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", width: "40%", marginTop: "5%", fontSize: "18px", borderRadius: '10px' }}
                    onClick={() => handleButtonClick('Container3', 'imc')}
                >
                    Cancelar
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    style={{ textTransform: "none", width: "40%", marginTop: "5%", fontSize: "18px", marginLeft: "2%", borderRadius: '10px' }}
                >
                    Actualizar
                </Button>

                <Toaster
                    position="top-center"
                    fontWeight="10"
                    reverseOrder={false}
                    gutter={9}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                     
                        className: '',
                        duration: 2000,
                        style: {
                            background: '#ffffff',
                            color: '#000',
                            fontSize: '15px'
                        },

                        success: {
                            duration: 2000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }}

                />
            </div>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialog }}
                style={{ zIndex: 10000 }}
            >
                <Typography id="alert-dialog-title" className={classes.titlemodal}>Confirmar actualización</Typography>
                <DialogContent>
                    <p>¿Está seguro de que desea actualizar sus datos? Recuerde que la información debe ser verídica para garantizar una buena medición.</p>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", width: "30%", marginTop: "5%", fontSize: "18px", borderRadius: '10px' }}
                   onClick={handleCloseModal}
                >
                    Cancelar
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", width: "30%", marginTop: "5%", fontSize: "18px", borderRadius: '10px' }}
                    onClick={() => {
                        handleCloseModal();
                        handleeditar();
                    }}
                >
                    Aceptar
                </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default EditUser;
