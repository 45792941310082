import React from 'react';
import { Grid } from '@material-ui/core';
import '../../Styles/stylesIMC.css';
import LineChartIGC from './LinechartIGC';


const HistoricoIGC = ({ DataRangos, data, genero }) => {

    return (
        <div className="card-Historic-IGC">
            <div className="grafica-IGC-historic">
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ height: "20%" }}>
                        <LineChartIGC width="100%" data={data} DataRangos={DataRangos} genero={genero} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ height: "20%" }}>
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}

export default HistoricoIGC;