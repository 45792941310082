import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './app/redux/store';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Copyright } from './dashboard/componet/UI/CopyRight';
import Footer from './centraldevida/component/footer/footer';
import DatosUsuario from './centraldevida/component/footer/datosUsuario';
import { selectUser } from './user/domain/userSlice';





ReactDOM.render(

  // <div className="container">
  // <div className="inner-container">
  <React.StrictMode>
    <Provider store={store}>
      <App >
      </App>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
  // </div>
  // </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload();
    }
  },
});