import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { FaQuestion, FaRegCalendarDays, FaStar } from "react-icons/fa6";
import '../../Styles/cardsStyles.css'
import NumberIcon from './NumberIcon';
import TablaOxiSVG from './graficas/TablaOxiSVG';
import TrianglesOxiRow from './graficas/TriangulosOxiSVG';



const calcularTamañoHollow = (DataRangos, oximetria) => {
    const oximetriaNum = parseFloat(oximetria);

    let selectedRangoOximetria = null;

    for (const rango of DataRangos) {
        const parametros = JSON.parse(rango.parametros);

        if (parametros && parametros.oxigeno) {
            const rangoOximetria = parametros.oxigeno.split('-').map(Number);


            if (
                oximetriaNum <= rangoOximetria[0] &&
                oximetriaNum >= rangoOximetria[1]
            ) {
                if (!selectedRangoOximetria || rangoOximetria[1] > selectedRangoOximetria.oxigeno) {
                    selectedRangoOximetria = { ...rango, oxigeno: rangoOximetria[1] };
                }
            }
        }
    }

    if (selectedRangoOximetria) {
        const label = selectedRangoOximetria.valor;
        const descripcion = selectedRangoOximetria.descripcion;

        return {
            label,
            descripcion,
        };
    } else {
        return {
            label: 'No se encuentra en ningún rango',
            descripcion: '',
        };
    }
};

const CardOximetria = ({ oximetrias, DataRangos, handleComponentChange, handleComponentRango, textStyle }) => {
    const [Rango, setRango] = useState('');
    const [Descripcion, setDescripcion] = useState('');
    const [showDescription, setShowDescription] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const [colorRango, setColorRango] = useState("#232323");

    const oximetria = oximetrias?.oximetria ?? 0;
    const oximetriaNum = oximetria !== null ? parseFloat(oximetria) : 0;
    const baseValue = Math.floor(oximetriaNum) + (oximetriaNum % 3 === 0 ? 3 : -3);
    const seriesData = Array.from({ length: 50 }, (_, i) =>
        baseValue + 10 * Math.sin(i / 5)
    );

    const handleButtonClick = (accion, key) => {
        handleComponentChange(accion);
        handleComponentRango(key)

    };

    useEffect(() => {
        if (DataRangos.length > 0) {
            const { label, descripcion } = calcularTamañoHollow(DataRangos, oximetriaNum);
            setRango(label);
            setDescripcion(descripcion);
        }

        const timeoutId = setTimeout(() => {
            if (oximetrias != null) {

                setShowDescription(true);
            }
            setShowChart(true);
        }, 500);

        return () => clearTimeout(timeoutId);
        
    }, [DataRangos, oximetrias]);
    const radialChartOptions = {
        chart: {
            type: 'area',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        series: [{
            data: seriesData,
            color: colorRango,
        }],
        stroke: {
            curve: 'smooth',
            width: 1
        },
        yaxis: {
            max: 100,
            min: 20,
            show: false
        },
        xaxis: {
            show: false,
            labels: {
                show: false
            }
        },
        grid: {
            clipMarkers: false,
            yaxis: {
                lines: {
                    show: false
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'vertical',
                gradientToColors: [colorRango],
                colorStops: [
                    {
                        offset: 0,
                        color: colorRango
                    },
                    {
                        offset: 80,
                        color: '#f2f2f2'
                    }
                ]
            }
        },
        tooltip: {
            enabled: false
        }
    };
    const fuente = 'Segoe UI Variable Static Text 700';
    return (

        <div className="card2" >
            <div className="card-content2">
                <div id="chart2" >
                    
                        <div className="grafica-radial2">
                            <div className='lagrafica'>
                                <div className="number-icon">
                                    {colorRango != "" && (<NumberIcon number={oximetriaNum} fuente={fuente} colorRango={colorRango} />)}
                                </div>
                                <div id="chart" className='graficaoxi'>
                                    <ApexCharts options={radialChartOptions} series={radialChartOptions.series} type="area" height={120} width={325} />
                                </div>
                            </div>
                            <div className='contenedortablaoxi'>
                                <TablaOxiSVG setColorRango={setColorRango} percentage={oximetriaNum} />
                                <TrianglesOxiRow />
                            </div>
                            {showDescription && (
                            <div>
                                <div className='titledescription' style={textStyle}>{Rango}</div>
                                <div className='descripcionoxi' style={{ textAlign: 'justify', color: textStyle.color }}>{Descripcion}</div>
                            </div>
                             )}
                        </div>
                    <div className="botones-container2">
                        <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
                            <FaQuestion style={textStyle} />
                            <p style={textStyle}>Información</p>
                        </button>
                        <button className="botonescard" onClick={() => handleButtonClick('Container10', 'oximetria')} style={textStyle}>
                            <FaRegCalendarDays style={textStyle} />
                            <p style={textStyle}>Historial</p>

                        </button>
                        <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
                            <FaStar style={textStyle} />
                            <p style={textStyle}>Tips</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardOximetria;
