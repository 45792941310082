import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default function Title({children, align, component='h2'}) {
    return (
        <Typography component={component} variant="h6" align={align} color="primary" gutterBottom>
            {children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};