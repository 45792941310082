import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { FaQuestion, FaRegCalendarDays, FaStar } from "react-icons/fa6";
import '../../Styles/stylesIMC.css'
// import RangeigcSVG from './graficas/RangeigcSVG';
// import TablaigcSVG from './graficas/TablaigcSVG';
// import TrianglesigcRow from './graficas/TriangulosigcSVG';
import { colors } from '@material-ui/core';

const calcularTamañoHollow = (DataRangos, genero, Igc) => {
    const generoKey = genero === 'M' ? 'hombre' : 'mujer';

    for (const rango of DataRangos) {
        const parametros = JSON.parse(rango.parametros);

        // Verifica si el rango es para el género actual
        if (parametros && parametros[generoKey]) {
            const [min, max] = parametros[generoKey].split('-').map(Number);

            // Comprueba si Igc está dentro del rango
            if (Igc >= min && Igc <= max) {
                const label = rango.valor;
                const descripcion = rango.descripcion;

                return {
                    label,
                    descripcion,
                };
            }
        }
    }


    return {
        label: '',
        descripcion: '',
    };
};
const CardVisceral = ({ Igc, genero, DataRangos, handleComponentChange, handleComponentRango, textStyle,peso}) => {

    const [showDescription, setShowDescription] = useState(false);
    const [Rango, setRango] = useState('');
    const [ColorRango, setColorRango] = useState('');
    const generoKey = genero === 'M' ? 'hombre' : 'mujer';
    const [Descripcion, setDescripcion] = useState('');
    const igcdefinido = Igc === null ? 0 : Igc == undefined ? 0 : Igc
    const igcParts = igcdefinido.toString().split('.'); // Divide el número en dos partes
    const handleButtonClick = (accion, key) => {
        handleComponentChange(accion);
        handleComponentRango(key)

    };
    const igcString = igcdefinido.toString(); // Convertir a cadena

    if (igcParts[1] !== undefined) {
        igcString += '.' + igcParts[1].charAt(0); // Parte decimal con un solo dígito
    }
    
    const igcNumber = parseFloat(igcString.replace(',', '.')); // Reemplazar coma por punto y convertir a número
    
    const truncarDecimales = (cadenaNumerica) => {
        const [parteEntera, parteDecimal] = cadenaNumerica.split('.');
        return parteDecimal ? `${parteEntera}.${parteDecimal.charAt(0)}` : cadenaNumerica;
      };
    useEffect(() => {
        if (DataRangos && DataRangos.length > 0) {
            const { label, descripcion } = calcularTamañoHollow(DataRangos, genero, Igc);
            setRango(label);
            setDescripcion(descripcion);
        } else {
            setRango('No hay rangos disponibles');
            setDescripcion('');
        }
        const timeoutId = setTimeout(() => {
            setShowDescription(true);
        }, 500);

        return () => clearTimeout(timeoutId); // Limpiar el timeout al desmontar el componente

    }, [Igc, DataRangos, genero, ColorRango]);

    const options = {
        chart: {
            height: 350,
            type: 'radialBar',
        },
        // los dos que se sumaron a la serie hay que eliminarlos solo se pusieron para que se vea el grafico cuando hayan datos reales eliminar
        series: [((igcNumber / peso) * 100).toFixed(1)],
        colors: [ColorRango],
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#F2F2F2',
                    startAngle: -90,
                    endAngle: 90,
                },
                dataLabels: {
                    showOn: 'always',
                    name: {
                        offsetY: -35,
                        show: false,
                        color: ColorRango,
                        fontSize: '18px',
                    },
                    value: {
                        fontWeight: "bold",
                        color: ColorRango,
                        offsetY: -20,
                        fontSize: '50px',
                        show: true,
                        formatter: function (val) {
                      
                            // Verificar si igcNumber es cero
                            if (igcNumber === 0) {
                                return '0%';
                            }
                        
                            // Verificar si igcNumber es un número entero
                            if (Number.isInteger(igcNumber)) {
                                return igcNumber.toFixed(0) + '%';
                            }
                        
                            else{
                                return truncarDecimales(igcNumber.toFixed(2)) + '%';
                              }
                        }
                    },
                },
            },
        },

        stroke: {
            lineCap: 'butt',
            dashArray: 4,
        },
        // labels: [Rango],

    };

    useEffect(() => {
   
        // Resto del código...
    }, [Igc, DataRangos, genero, ColorRango]);
    return (
        <div className="cardSolografica">
            <div className="card-content">
                {showDescription && (
                    <div className="grafica-radial-Solografica">
                        <ApexCharts options={options} series={options.series} type="radialBar" height={300} />
                        <div>
                            {(igcdefinido === '0' || igcdefinido === 0) && (
                                <div className='descripcion' style={{marginTop:'-15px', marginBottom: '10px', color: 'white', backgroundColor: textStyle.color, borderRadius: '10px', marginLeft: '-5%' }}>
                                    No cuentas con esta medición. Acércate a la EVBI más cercana.
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="botonesigc">
                <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
                        <FaQuestion style={textStyle} />
                        <p style={textStyle}>Información</p>
                    </button>
                    <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
                        <FaRegCalendarDays style={textStyle} />
                        <p style={textStyle}>Historial</p>

                    </button>
                    <button className="botonescard" onClick={() => handleButtonClick('Container15', 'imc')}>
                        <FaStar style={textStyle} />
                        <p style={textStyle}>Tips</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CardVisceral;
