import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import '../../Styles/stylesIMC.css';
import LineChartOXI from './LinechartOxi';


const HistoricoOXI = ({  DataRangos, data }) => {


    return (
        <div className="card-Historic-IGC">
            <div className="grafica-IGC-historic">
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ height: "20%" }}>
                        <LineChartOXI width="100%" data={data} DataRangos={DataRangos}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                </Grid>

            </div>
        </div>
    );
}

export default HistoricoOXI;