import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, AreaChart } from 'recharts';
import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    LabelList,
    Legend,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts';
import Title from '../../../Title';
import { UtilsDate } from '../../../../../orussystem/utils/UtilDate';
import { Bar } from 'recharts';
import { BarChart } from 'recharts';

import { CartesianGrid } from 'recharts';
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(15),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
// Generate Sales Data
function createData(time, amount) {
    return { time, amount };
}


export default function PlusPresionAterial({ dataPA }) {
    const theme = useTheme();
    const history = useHistory()
  
    const dataPresion = dataPA.map(item =>{
        return({
            name: UtilsDate().formatDDMM(new Date(item.datecreate)),
           // ...item,
            optima: item.diastolica < 50 && item.diastolica  > 80  ? item.diastolica : 0 || item.sistolica < 60 && item.sistolica  > 120  ? item.sistolica : 0,
            normal: item.diastolica < 80 && item.diastolica > 85  ? item.diastolica : 0 || item.sistolica < 120 && item.sistolica  > 130  ? item.sistolica : 0,
            sospecha: item.diastolica < 85 && item.diastolica > 90  ? item.diastolica : 0 || item.sistolica < 130 && item.sistolica  > 140  ? item.sistolica : 0,
            hipertensionArterial: item.diastolica >= 90 ? item.diastolica : 0  || item.sistolica  >= 140  ? item.sistolica : 0,
        })
     
    })
    

    const goBack = () => {
        history.push("/")
    }
    const customTooltip = (value, name, props) => {
        if(name == 'diastolica'){
            return 'Valores menores a 80';
        }else if(name == 'sistolica'){
            return 'Valores menores a 130';
        }
      
      };

    return (
        <>
            <Title>Presion arterial</Title>
                    <ResponsiveContainer width="100%" height="100%" >
                    <ComposedChart
                    layout="vertical"
                    width={500}
                    height={300}
                    data={dataPresion}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" scale="band" />
                    <Bar dataKey="optima" stackId="a" fill="#000000" />
                    <Bar dataKey="normal" fill="#2bb673" />     
                    <Bar dataKey="sospecha" fill="#fcd704"  />
                    <Bar dataKey="hipertensionArterial" fill="#ec1f26"  />
                </ComposedChart>
                    </ResponsiveContainer>
          
            <Grid container spacing={2} style={{ padding: 0, marginTop: '10px', marginLeft: '1px', }}>
                 <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color:"#41c8f5" }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Hipotensión</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Hipotensión: "}</b>{' '}
                                            {"Valores menores a 40 y 50."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color:"#1BFF09" }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Óptima</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Óptima: "}</b>{' '}
                                            {"Valores de tensión entre 50 y 80 en Diastólica o entre 60 y 120 en Sistíloca."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color:"#2bb673"}} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Normal</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Normal: "}</b>{' '}
                                            {"Valores de tensión entre 80 y 85 en Diastólica o entre 120 y 130 en Sistíloca"}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color:"#fcd704" }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Sospecha</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Sospecha: "}</b>{' '}
                                            {"Valores de tensión entre 85 y 90 en Diastólica o entre 130 y 140 en Sistíloca"}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px' }}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon style={{ color:"#ec1f26" }} />
                            </Grid>

                            <Grid item xs={12} style={{ fontSize: '17px', display: "flex", alignItems: "center", marginRight: '10px' }}>Hipertensión Arterial</Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>Descripción</Typography>
                                            <b><br />{"Hipertensión Arterial: "}</b>{' '}
                                            {"Valores de tensión mayores o iguales a 90 en Diastólica o mayores o iguales a 140 en Sistíloca."}
                                        </React.Fragment>
                                    }
                                    placement="right-start"
                                >
                                    <HelpOutlineIcon style={{ color: "#7F7F7F", height: '21px' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>   



            <Button
                variant="contained"
                color="primary"
                style={{ textTransform: "none", width: "inherit", marginTop: 20 }}
                onClick={goBack}
            >
                Volver
            </Button>
        </>
    );
}

