import React from 'react';
import '../../Styles/cardsStyles.css'

const NumberIcon = ({ number,fuente,colorRango }) => {
  return (
    <svg className="marea-container"
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="150"
      viewBox="0 0 100 100"
    >
      <text
        x="21%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="42"
        fontWeight="bold"
        fill="none" 
        stroke={colorRango} 
        strokeWidth="1" 
        className="marea-text"
      >
        {number}%

      </text>
      <text
        x="117%"
        y="60%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="20"
        fontWeight="bold"
        fill="none"
        className="marea-text"
        stroke={colorRango}
        strokeWidth="1" 
        fontFamily={fuente}
      >
        SpO2
      </text>
    </svg>
  );
};

export default NumberIcon;