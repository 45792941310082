import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import '../../Styles/stylesIMC.css';
import LineChart from './LineChartWithAnnotations';
import LineChartPeso from './LineChartPeso';


const HistoricoIMC = ({ DataRangos, data, altura, genero, textStyle }) => {

    return (
        <div className="card">
            <div className="grafica-radial-historic">
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ height: "20%" }}>
                        <div className='titlegraphic' style={textStyle}>IMC</div>

                        <LineChart width="100%" data={data} DataRangos={DataRangos} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ height: "20%" }}>
                        <div className='titlegraphic' style={textStyle}>Peso</div>

                        <LineChartPeso width="100%" data={data} DataRangos={DataRangos} altura={altura} genero={genero} />
                    </Grid>
                </Grid>

            </div>
        </div>
    );
}

export default HistoricoIMC;