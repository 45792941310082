import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, AreaChart } from 'recharts';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LabelList,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import Title from './Title';

// Generate Sales Data
function createData(time, amount) {
    return { time, amount };
}


const data = [
    {
        name: '20/04',
        obesidad: 35,
        sobrepeso: 30,
        normal: 25,
        bajo: 18,
        imc: 26,
    },
    {
        name: '21/04',
        obesidad: 35,
        sobrepeso: 30,
        normal: 25,
        bajo: 18,
        imc: 26,
    },
    {
        name: '22/04',
        obesidad: 35,
        sobrepeso: 30,
        normal: 25,
        bajo: 18,
        imc: 25.5,
    },
    {
        name: '23/04',
        obesidad: 35,
        sobrepeso: 30,
        normal: 25,
        bajo: 18,
        imc: 27,
    },
    {
        name: '24/04',
        obesidad: 35,
        sobrepeso: 30,
        normal: 25,
        bajo: 18,
        imc: 25,
    },
    {
        name: '25/04',
        obesidad: 35,
        sobrepeso: 30,
        normal: 25,
        bajo: 18,
        imc: 24,
    },
];


export default function Chart() {
    const theme = useTheme();

    return (
        <>
            <Title>Indice de Masa Corporal</Title>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={500}
                    height={600}
                    data={data}
                    margin={{
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 20,
                    }}
                >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    {/* <CartesianGrid stroke="#f5f5f5" /> */}
                    <XAxis dataKey="name" />
                    <YAxis domain={['dataMin - 3', 'dataMax']} label={{ value: 'IMC', angle: -90, position: 'insideLeft' }} />
                    {/* <Tooltip /> */}
                    <Legend />
                    <Area type="monotone" dataKey="obesidad" fill="#fff" stroke="#ffc658" />
                    <Area type="monotone" dataKey="sobrepeso" fill="#fff" stroke="#8884d8" />
                    <Area type="monotone" dataKey="normal" fill="#fff" stroke="#82ca9d" />
                    <Area type="monotone" dataKey="bajo" fill="#fff" stroke="#FD8244" />
                    <Line type="monotone" dataKey="imc" stroke="#000" strokeWidth={1.5}  activeDot={{ r: 8 }}  >
                        <LabelList dataKey="imc" position="top" />
                    </Line>
                </ComposedChart>
            </ResponsiveContainer>
        </>
    );
}