import React, { useEffect, useState } from 'react';

import './footerStyles.css';

const calcularTamañoHollow = (DataRangos, genero, Igc) => {
    const generoKey = genero === 'M' ? 'hombre' : 'mujer';

    for (const rango of DataRangos) {
        const parametros = JSON.parse(rango.parametros);

        // Verifica si el rango es para el género actual
        if (parametros && parametros[generoKey]) {
            const [min, max] = parametros[generoKey].split('-').map(Number);

            // Comprueba si Igc está dentro del rango
            if (Igc >= min && Igc <= max) {
                const label = rango.valor;
                const descripcion = rango.descripcion;

                return {
                    label,
                    descripcion,
                };
            }
        }
    }

    return {
        label: '',
        descripcion: '',
    };
};

export default function TusDatosIGC({ Igc, DataRangos, genero, primaryColor }) {
    const [Rango, setRango] = useState("");

    useEffect(() => {
        if (DataRangos && DataRangos.length > 0) {
            const { label } = calcularTamañoHollow(DataRangos, genero, Igc);
            setRango(label);
        } else {
            setRango('No hay rangos disponibles');
        }
    }, [Igc, DataRangos, genero]);

    // Formatear el IGC con un solo decimal si es un número decimal
    const formattedIgc = !isNaN(Igc) ? (Number(Igc) % 1 === 0 ? Number(Igc) : Number(Igc).toFixed(2)) : '-';

    return (
        <div className='buttonHistoricPresion'>
            <button className="buttonUnic">
                <div className="number" style={{ color: primaryColor }}><b>{formattedIgc}%</b></div>
                <div className="text" style={{ color: primaryColor }}>{Rango}</div>
            </button>
        </div>
    );
}
