import { request } from "../../../app/api-client/ApiClient";


export function getUserMachine(phone) {
	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v2/user/machine/phone/${phone}`,
		method: "GET",
	});
}

export function getUserDocumentMachine(document) {
	return request({
		url: `${process.env.REACT_APP_API_EVBI}/v1/user/historic/${document}/0`,
		method: "GET",
	});
}
export function getRangos(key) {
	return request({
		url: `${process.env.REACT_APP_API_EVBI}/v1/rango/module/${key}`,
		method: "GET",
	});
}
export function getHistorico(documento) {
	return request({
		url: `${process.env.REACT_APP_API_EVBI}/v1/user/module/historic/${documento}/imc?page=0&size=30`,
		method: "GET",
	});
}
//editarusuario
export function EditarUsuario(dispatch, Request) {
	let url = `${process.env.REACT_APP_API_EVBI}/v1/user/update`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	}, dispatch);
}






export function getUserMachineNew(phone) {
	//https://qa-backbascula.orussystem.com/Bascula/v2/user/machine/historic/user/79660125
	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v2/user/machine/historic/user/${phone}`,
		method: "GET",
	});
}

export function getHistoricMachineByPhones(phone) {
	
	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v2/user/machine/list/user/documento/celular/${phone}`,
		method: "GET",
	});
}
export function updateUserPerfil (dispatch, Request){

	let url = `${process.env.REACT_APP_API_BASCULA}/v2/user/machine/updateOtherData`;
	return request({
		url: url,
		method: "PUT",
		body: JSON.stringify(Request),
	}, dispatch);
}

export function getQuetiosByPoll(idPoll) {

	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v1/poll/question?id=${idPoll}`,
		method: "GET",
	});

}

export function getQuetiosPublic(id) {

	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v1/poll/question?id=${id}`,
		method: "GET",
	});

}


export function setPollDataRequest(idUser,obj) {
	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v1/poll/user/${idUser}`,
		method: "POST",
		body: JSON.stringify(obj),
	});
}

export function setPollDataRequesPublic(idCell,obj) {
	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v1/poll/user/cell/${idCell}`,
		method: "POST",
		body: JSON.stringify(obj),
	});
}


export function findQuestionByUserAndPoll(idUser, idPoll) {

	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v1/poll/question/${idUser}?idPoll=${idPoll}`,
		method: "GET",
	});

}

//Listar encuestas activas
export function getListAllPoll(page, size) {
	return request({
		url: `${process.env.REACT_APP_API_BASCULA}/v1/poll/list/activos?page=${page}&size=${size}`,
		method: "GET",
	});

}

