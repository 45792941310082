import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
} from '@material-ui/core';

import MigaPan from '../component/Utils/MigaPan';
import { FormUser } from './componets/formUser';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMachine, updateUserPerfil } from '../domain/api-client/UserMachine';
import { selectUser } from '../../user/domain/userSlice';
import { request } from '../../app/api-client/ApiClient';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));


export const PanelUser = (props) => {

    const [userMachine, setUserMachine] = useState({});
    const [isData, setisData] = useState(false)
    const classes = useStyles();
    const userAuth = useSelector(selectUser);


    useEffect(async () => {
        const result = await getUserMachine(userAuth.userPrincipal.username);
        const user = result.respuesta.user;
        setUserMachine(user);
        setisData(true)

    }, []);

    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Perfil Usuario" />
            </Grid>

            {isData &&
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FormUser data={userMachine} />
                    </Paper>
                </Grid>
            }


        </>
    )

}