import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { loginBytoken, logout } from '../../domain/userSlice';
import { ACCESS_TOKEN } from '../../domain/constants';

const withAuthentication = Component => (props) => {

  // const dispatch = useDispatch();

  // useEffect(() => {

  //   setDispatch();

  // }, [dispatch]);

  // const setDispatch = async () => {
  //   let token = localStorage.getItem(ACCESS_TOKEN);
  //   token
  //     ? await dispatch(loginBytoken())
  //     : await dispatch(logout());
  // }

  return <Component {...props} />;
};

export default withAuthentication;